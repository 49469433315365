import React from 'react';
import { Button } from '@mui/material';
import T, { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { NotifyTypo } from 'web-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyledContent, StyledHandlers, StyledNewDialog } from '../elements';
import { gatewayV2UnbindOrganizationAction } from '../../../../redux/gateways_v2/actions';

const labelAmmend = 'gateways.detail.unlink_modal';

const UnLinkGateway = ({ handleModalParams, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  /**
   * Unlink gateway from organization
   * @returns {Promise<void>}
   */
  const handleUnLinkGateway = async () => {
    try {
      if (handleModalParams.row.gatewayId !== '' && handleModalParams.row.organizationId !== '') {
        dispatch(
          gatewayV2UnbindOrganizationAction({
            gatewayId: handleModalParams.row.gatewayId,
            organizationId: handleModalParams.row.organizationId
          })
        );
        if (handleModalParams?.goesTo) history.push(handleModalParams?.goesTo);
      }
      handleClose(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.error_create_clone'), errorToastProps);
      }
    }
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={() => handleClose()}>
        {t(`${labelAmmend}.cancel`)}
      </Button>
      <Button
        variant="contained"
        data-testid="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        color="error"
        onClick={handleUnLinkGateway}
      >
        {t(`${labelAmmend}.action`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={handleModalParams.open}
      handleClose={handleClose}
      title={t(`${labelAmmend}.title`)}
      subTitle={t(`${labelAmmend}.subtitle`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="680px"
      content={
        <StyledContent>
          <NotifyTypo.Heading2>{t(`${labelAmmend}.text`)}</NotifyTypo.Heading2>
        </StyledContent>
      }
    />
  );
};

UnLinkGateway.propTypes = {
  handleModalParams: T.shape({
    open: T.bool.isRequired,
    row: T.shape({
      organizationId: T.string,
      gatewayId: T.string
    }),
    goesTo: T.string
  }).isRequired,
  handleClose: func.isRequired
};

export default UnLinkGateway;

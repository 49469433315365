import { Box, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { objectToMap } from 'helpers/utils';
import { getValueToNullableCell } from 'helpers/stringHandler';
import EmptyState from 'components/EmptyState';
import Divider from '@mui/material/Divider';

export default function GatewayNetwork({ gatewayDetails }) {
  const { t } = useTranslation();
  const [localAdaptersList, setLocalAdaptersList] = useState([]);
  const [publicIp, setPublicIp] = useState('');

  useEffect(() => {
    if (gatewayDetails) {
      const nt = gatewayDetails?.info?.network;
      const la = nt?.local_adapters;
      setLocalAdaptersList(la && la.length > 0 ? la : []);
      setPublicIp(nt?.public_ip || '');
    }
  }, [gatewayDetails]);
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-testid="gateway-network"
    >
      <Stack direction="column" spacing={3}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('gateway_details.network.title')}</Typography>
        {localAdaptersList.length > 0 && publicIp ? (
          <>
            <Box
              sx={{
                p: 3
              }}
            >
              <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                {t('gateway_details.network.local_adapters.title')}
              </Typography>
              {localAdaptersList.map((item, idxL) => {
                const networkMap = objectToMap(item);
                return (
                  <div>
                    {idxL !== 0 && (
                      <Divider
                        sx={{
                          marginBlockStart: '24px !important',
                          marginBlockEnd: '24px !important'
                        }}
                      />
                    )}
                    <List>
                      <ListItem>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          rowSpacing={3}
                          columnSpacing={2}
                          columns={4}
                        >
                          {Array.from(networkMap).map(([key, value], idxM) => (
                            <Grid item xs={1} sx={{ pl: idxM % 4 === 0 ? '0px !important' : undefined }}>
                              <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                                <Typography
                                  sx={{ fontSize: 12, color: '#7A7D85' }}
                                  data-testid={`gateway-versions-network-key-${key}`}
                                  gutterBottom
                                >
                                  {key}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: 16 }}
                                  data-testid={`gateway-versions-network-value-${key}`}
                                  gutterBottom
                                >
                                  {getValueToNullableCell(value)}
                                </Typography>
                              </Stack>
                            </Grid>
                          ))}
                        </Grid>
                      </ListItem>
                    </List>
                  </div>
                );
              })}
            </Box>
            <Box
              sx={{
                p: 3
              }}
            >
              <Stack direction="column" spacing={3}>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                  {t('gateway_details.network.public_ip.title')}
                </Typography>
                <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
                  <Typography sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
                    {t('gateway_details.network.public_ip.title')}
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} data-testid="gateway-versions-public-ip" gutterBottom>
                    {getValueToNullableCell(publicIp)}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </>
        ) : (
          <Box sx={{ py: 2 }}>
            <EmptyState type="gtw_network" />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

GatewayNetwork.propTypes = {
  gatewayDetails: T.shape({
    info: T.shape({ network: T.shape({ local_adapters: T.arrayOf(T.shape()), public_ip: T.string }) })
  }).isRequired
};

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ErrorCodeTypes, FormElements } from 'web-components';
import { Button, Container } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import SelectErrorType from './CustomErrorForms/SelectErrorType';
import CustomErrorFields from './CustomErrorForms/CustomErrorFields';
import ErrorTypeList from './CustomErrorForms/ErrorTypeList';
import { getFormikError, getFormikHelperText, validateBlankSpace } from '../../../../../helpers/utils';
import { ErrorCodesSchema } from '../../../../../attrs/formValidation';
import { StyledContent, StyledHandlers, StyledNewDialog } from '../elements';
import { createErrorCode, updateErrorCode } from '../../../../../redux/machines/actions';

const labelAmend = 'machines.machine_details.error_code_modal';

const formatErrorCode = (errorCode, t) => {
  if (!errorCode.is_custom) {
    const currentErrorCode =
      ErrorCodeTypes.find(item => item.value === (errorCode.value ? errorCode.value : errorCode.type)) || {};
    return `${currentErrorCode.value}: ${t(`machines.${currentErrorCode.name}`)}`;
  }
  return (
    <>
      <b>{t('machines.form.error.custom_error')}:</b> {errorCode.custom_message}
    </>
  );
};

const ErrorCodeFormModal = ({ listedValues, isNewError, isCustom, open, handleClose, machineId, editError }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      machine_id: machineId,
      machine_error: {
        custom_bool_val: values.custom_type === 'BOOL' ? values.custom_bool_val : null,
        custom_int_val: values.custom_type === 'INT' ? values.custom_int_val : null,
        custom_message: values.custom_message,
        custom_type: values.custom_type,
        is_custom: isCustom,
        source: values.source,
        type: values.type,
        pre_def_error_id: values.pre_def_error_id,
        custom_error_id: values.custom_error_id
      }
    };
    try {
      if (isNewError) {
        dispatch(createErrorCode(machineId, payload));
      } else {
        dispatch(updateErrorCode(machineId, payload));
      }
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('form.error_error_code'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, isSubmitting, resetForm) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        type="button"
        variant="contained"
        data-testid="license-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.button_action`)}
      </Button>
    </StyledHandlers>
  );

  const initialValues = editError ?? {
    source: '',
    type: '',
    custom_type: 'INT',
    custom_message: '',
    is_custom: isCustom,
    custom_bool_val: undefined,
    custom_int_val: undefined,
    pre_def_error_id: null,
    custom_error_id: null
  };

  const handleChangeAddress = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      validationSchema={ErrorCodesSchema}
      enableReinitialize
    >
      {({
        setFieldValue,
        errors,
        handleBlur,
        handleChange,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        resetForm
      }) => (
        <StyledNewDialog
          open={open}
          handleClose={(event, reason) => {
            if (reason && reason === 'backdropClick') return;
            resetForm();
            handleClose();
          }}
          title={t(`${labelAmend}.${isNewError ? 'title' : 'title_edit'}`)}
          subTitle={t(`${labelAmend}.${isNewError ? 'subtitle' : 'subtitle_edit'}`)}
          displayActionBar="true"
          actionBar={actionBar(handleSubmit, errors, isSubmitting, resetForm)}
          maxWidth="680px"
          content={
            <StyledContent>
              <Container maxWidth="sm">
                <SelectErrorType error={values} setFieldValue={setFieldValue} namespace={null} />
                <ErrorTypeList
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  error={values}
                  namespace={null}
                  listedValues={listedValues}
                  getHelperText={getFormikHelperText({ errors, t })}
                  getError={getFormikError({ errors, touched })}
                  formatErrorCode={formatErrorCode}
                />
                <FormElements.TextField
                  error={getFormikError({ errors, touched })('source')}
                  helperText={getFormikHelperText({ errors, t })('source')}
                  fullWidth
                  id="source"
                  label={t('machines.form.error_code.source')}
                  margin="normal"
                  name="source"
                  onBlur={handleBlur}
                  onChange={handleChangeAddress(handleChange)}
                  required
                  value={values.source}
                />
                <CustomErrorFields
                  handleBlur={handleBlur}
                  error={values}
                  setFieldValue={setFieldValue}
                  getHelperText={getFormikHelperText({ errors, t })}
                  getError={getFormikError({ errors, touched })}
                />
              </Container>
            </StyledContent>
          }
        />
      )}
    </Formik>
  );
};

ErrorCodeFormModal.propTypes = {
  listedValues: T.arrayOf(T.shape({})),
  isNewError: T.bool,
  isCustom: T.bool.isRequired,
  open: T.bool.isRequired,
  handleClose: T.func.isRequired,
  machineId: T.string.isRequired,
  editError: T.shape({
    source: T.string,
    type: T.string,
    custom_type: T.string,
    custom_message: T.string,
    is_custom: T.bool,
    custom_bool_val: T.bool,
    custom_int_val: T.number,
    pre_def_error_id: T.number,
    custom_error_id: T.number
  })
};

ErrorCodeFormModal.defaultProps = {
  isNewError: false,
  listedValues: [],
  editError: null
};

export default ErrorCodeFormModal;

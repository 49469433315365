import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import { ResponsiveIconButton, SensorTypes } from 'web-components';
import { CardContent, Container } from '@mui/material';
import EmptyState from 'components/EmptyState';
import { setConfirmationRequest } from 'redux/ui/confirmations/actions';
import { getSensorPropsFromType, isNullUndefinedOrEmpty } from 'helpers/utils';
import { StyledCard, StyledContentHeaderColumn, StyledContentWrapper } from '../elements';
import NewSensorForm from './NewSensorForm';
import SensorDataCard from './SensorDataCard';
import {
  MachineBreadcrumb,
  MachinePageHeader,
  MachinePageHeaderWithAddButton,
  PageHeaderGroupTitles
} from '../../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../../elements';
import { metricDataType } from '../../../../../attrs/sensorConfig';

// TODO
// TEMP FIX FOR _0x ident for additional sub type sensors _0x
const millSensorTypes = Object.keys(SensorTypes.mill).flatMap(type => {
  const types = [
    {
      name: `sensors.mill.${type}`,
      value: `mill_${type}_00`
    }
  ];

  const typeData = SensorTypes.mill[type];
  const subIdentifiers = typeData.subIdent ? Object.keys(typeData.subIdent) : [];

  if (subIdentifiers.length > 0) {
    subIdentifiers.forEach(subType => {
      types.push({
        name: `sensors.mill.${type}`,
        postfix: typeData.subIdent[subType].postfix || subType,
        value: `mill_${type}_${subType}`
      });
    });
  }

  return types;
});

const SensorData = ({
  getError,
  getHelperText,
  handleBlur,
  handleChange,
  handleChangeSensorType,
  handleChangeEngineeringDataType,
  setFieldValue,
  values
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [shouldShowNewSensorForm, toggleNewSensorForm] = useState(false);
  const [isNewSensor, toggleIsNewSensor] = useState(false);

  const handleAddNewSensor = (arrayHelpers, newSensorValues) => {
    arrayHelpers.push({ ...newSensorValues });
    toggleNewSensorForm(false);
    toggleIsNewSensor(false);
  };

  const hasNoMetrics =
    ((((values || {}).configuration || {}).plc || {}).metrics.filter(row => row.is_static === false) || []).length ===
    0;
  const shouldShowEmptyState = hasNoMetrics && !shouldShowNewSensorForm;
  const busySensorTypes = !hasNoMetrics ? values.configuration.plc.metrics.map(metric => metric.type) : [];

  const availableSensorTypes = millSensorTypes.filter(sensorType => !busySensorTypes.includes(sensorType.value));

  const confirmBeforeDeleting =
    onDelete =>
    (...props) => {
      dispatch(
        setConfirmationRequest({
          message: 'dialog.confirmation.delete_sensor_data',
          action: () => onDelete(...props)
        })
      );
    };

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.sensor_data.title')}` }
  ];

  return (
    <FieldArray name="configuration.plc.metrics">
      {arrayHelpers =>
        shouldShowEmptyState ? (
          <StyledContentWrapper elevation={3}>
            <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

            <MachinePageHeader>
              <PageHeaderTitle>{t('machines.detail.sensor_data.title')}</PageHeaderTitle>
              <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
            </MachinePageHeader>
            <Container maxWidth="sm">
              <StyledCard>
                <CardContent>
                  <EmptyState
                    type="machine_details_sensor"
                    actionButton={
                      <ResponsiveIconButton
                        data-testid="machine-details--new-sensor-button"
                        buttonText={t('machines.form.sensor.add_new_sensor')}
                        color="primary"
                        iconName="plus"
                        variant="outlined"
                        onClick={() => {
                          toggleIsNewSensor(true);
                          toggleNewSensorForm(true);
                        }}
                      />
                    }
                  />
                </CardContent>
              </StyledCard>
            </Container>
          </StyledContentWrapper>
        ) : (
          <StyledContentWrapper elevation={3}>
            <StyledContentHeaderColumn>
              <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

              <MachinePageHeaderWithAddButton>
                <PageHeaderGroupTitles>
                  <PageHeaderTitle>{t('machines.detail.sensor_data.title')}</PageHeaderTitle>
                  <PageHeaderSubTitle>{t('machines.detail.subtitle')}</PageHeaderSubTitle>
                </PageHeaderGroupTitles>

                <ResponsiveIconButton
                  buttonText={t('machines.form.sensor.add_new_sensor')}
                  color="primary"
                  iconName="plus"
                  variant="outlined"
                  onClick={() => {
                    toggleIsNewSensor(true);
                    toggleNewSensorForm(true);
                  }}
                />
              </MachinePageHeaderWithAddButton>
            </StyledContentHeaderColumn>

            <Container maxWidth="md" style={{ marginTop: '2rem' }}>
              {shouldShowNewSensorForm && (
                <NewSensorForm
                  onDelete={confirmBeforeDeleting(() => {
                    toggleIsNewSensor(false);
                    toggleNewSensorForm(false);
                  })}
                  onSubmit={newSensorValues => handleAddNewSensor(arrayHelpers, newSensorValues)}
                  availableSensorTypes={availableSensorTypes}
                  isNewSensor={isNewSensor}
                />
              )}
              {!hasNoMetrics &&
                values.configuration.plc.metrics
                  .filter(metric => metric.is_static === false)
                  .map((data, idx) => {
                    const metric = data;
                    // enhance available sensor types list with selected sensor type
                    const availableSensorTypesEnhanced = [
                      ...availableSensorTypes,
                      millSensorTypes.find(sensorType => sensorType.value === metric.type)
                    ];

                    if (!isNullUndefinedOrEmpty(metric.type) && isNullUndefinedOrEmpty(metric.units)) {
                      const { unit } = getSensorPropsFromType(metric.type);
                      metric.units = unit;
                    }

                    return (
                      <SensorDataCard
                        availableSensorTypes={availableSensorTypesEnhanced}
                        getError={getError}
                        getHelperText={getHelperText}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleChangeSensorType={handleChangeSensorType}
                        handleChangeEngineeringDataType={handleChangeEngineeringDataType}
                        setFieldValue={setFieldValue}
                        key={`sensor-data-card-${idx}`} // eslint-disable-line react/no-array-index-key
                        metric={metric}
                        namespace={`configuration.plc.metrics.${idx}`}
                        millSensorTypes={millSensorTypes}
                        onDelete={confirmBeforeDeleting(() => arrayHelpers.remove(idx))}
                        values={values}
                        isNewSensor={isNewSensor}
                      />
                    );
                  })}
            </Container>
          </StyledContentWrapper>
        )
      }
    </FieldArray>
  );
};

SensorData.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeSensorType: T.func.isRequired,
  handleChangeEngineeringDataType: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  values: T.shape({
    id: T.string,
    commission_number: T.string,
    configuration: T.shape({
      plc: T.shape({
        metrics: T.arrayOf(metricDataType.isRequired).isRequired
      })
    })
  }).isRequired
};

export default SensorData;

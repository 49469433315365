import { Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements } from 'web-components';
import { StyledHandlers, StyledNewDialog } from '../elements';
import { getFormikError, getFormikHelperText, isLoading } from '../../../../../../helpers/utils';
import { ManagementUnitsSchema } from '../../../../../../attrs/formValidation';
import { getSensorUnits } from '../../../../../../redux/sensor/selectors';
import { updateSensorUnits } from '../../../../../../redux/sensor/actions';

const labelAmend = 'machines.machine_details';

const EditManagementUnitsModal = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingSensorsUnitsStatus: loadingState } = useSelector(getSensorUnits);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    const payload = {
      units: {
        temperature_variable_name: values.temperature,
        pressure_variable_name: values.pressure,
        product_flow_variable_name: values.product_flow,
        product_mass_mass_flow_specific_density_and_specific_energy_variable_name: values.product_mass,
        liquid_flow_and_volume_variable_name: values.liquid_flow,
        mill_peripheral_speed_and_length_variable_name: values.mill_speed,
        current_decimal_places_variable_name: values.current,
        power_decimal_places_variable_name: values.power
      }
    };
    try {
      dispatch(updateSensorUnits(machineId, payload));
      formikHelpers.resetForm();
      handleClose();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => {
          handleSubmit();
        }}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t('default_actions.save')}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        temperature: data?.temperature_variable_name || '',
        pressure: data?.pressure_variable_name || '',
        product_flow: data?.product_flow_variable_name || '',
        product_mass: data?.product_mass_mass_flow_specific_density_and_specific_energy_variable_name || '',
        liquid_flow: data?.liquid_flow_and_volume_variable_name || '',
        mill_speed: data?.mill_peripheral_speed_and_length_variable_name || '',
        current: data?.current_decimal_places_variable_name || '',
        power: data?.power_decimal_places_variable_name || ''
      }}
      validationSchema={ManagementUnitsSchema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <>
            <StyledNewDialog
              open={open}
              handleClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleClose();
              }}
              title={t(`${labelAmend}.management_units_modal.title`)}
              subTitle={t(`${labelAmend}.management_units_modal.subtitle`)}
              maxWidth="978px"
              maxHeight="564px"
              displayActionBar="true"
              actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
              shouldCloseOnOverlayClick={false}
              content={
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('temperature')}
                      helperText={getHelperText('temperature')}
                      id="temperature"
                      label={t(`${labelAmend}.management_units_modal.temperature`)}
                      margin="normal"
                      name="temperature"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.temperature}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('pressure')}
                      helperText={getHelperText('pressure')}
                      id="pressure"
                      label={t(`${labelAmend}.management_units_modal.pressure`)}
                      margin="normal"
                      name="pressure"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pressure}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('product_flow')}
                      helperText={getHelperText('product_flow')}
                      id="product_flow"
                      label={t(`${labelAmend}.management_units_modal.product_flow`)}
                      margin="normal"
                      name="product_flow"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product_flow}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('product_mass')}
                      helperText={getHelperText('product_mass')}
                      id="product_mass"
                      label={t(`${labelAmend}.management_units_modal.product_mass`)}
                      margin="normal"
                      name="product_mass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.product_mass}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('liquid_flow')}
                      helperText={getHelperText('liquid_flow')}
                      id="liquid_flow"
                      label={t(`${labelAmend}.management_units_modal.liquid_flow`)}
                      margin="normal"
                      name="liquid_flow"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.liquid_flow}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('mill_speed')}
                      helperText={getHelperText('mill_speed')}
                      id="mill_speed"
                      label={t(`${labelAmend}.management_units_modal.mill_speed`)}
                      margin="normal"
                      name="mill_speed"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mill_speed}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('current')}
                      helperText={getHelperText('current')}
                      id="current"
                      label={t(`${labelAmend}.management_units_modal.current`)}
                      margin="normal"
                      name="current"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.current}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('power')}
                      helperText={getHelperText('power')}
                      id="power"
                      label={t(`${labelAmend}.management_units_modal.power`)}
                      margin="normal"
                      name="power"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.power}
                    />
                  </Grid>
                </Grid>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

EditManagementUnitsModal.propTypes = {
  data: T.shape({
    created_at: T.string,
    created_by: T.shape({}),
    updated_at: T.string,
    last_updated_by: T.shape({}),
    current_decimal_places_variable_name: T.string,
    liquid_flow_and_volume_variable_name: T.string,
    mill_peripheral_speed_and_length_variable_name: T.string,
    power_decimal_places_variable_name: T.string,
    pressure_variable_name: T.string,
    product_flow_variable_name: T.string,
    product_mass_mass_flow_specific_density_and_specific_energy_variable_name: T.string,
    temperature_variable_name: T.string
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditManagementUnitsModal.defaultProps = {
  data: null,
  machineId: null
};

export default EditManagementUnitsModal;

import React from 'react';
import { Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateBatchDetails } from 'redux/batch/actions';
import { getBatchReducerState } from 'redux/rootSelectors';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from 'helpers/utils';
import { useSnackbar } from 'notistack';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { BatchDetailsSchema } from '../../../../../attrs/formValidation';

const labelAmend = 'machines.machine_details';

const EditBatchRecords = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { updateBatchDetailsStatus } = useSelector(getBatchReducerState);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  function isSaving() {
    return isLoading(updateBatchDetailsStatus.status);
  }

  const submitForm = (values, formikHelpers) => {
    try {
      dispatch(updateBatchDetails(machineId, values));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeAddress = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={BatchDetailsSchema}
      initialValues={{
        batch_id: data.batch_source?.details.batch_id || '',
        batch_size: data.batch_source?.details.batch_size || '',
        cycles: data.batch_source?.details.cycles || '',
        est_energy_consumption: data.batch_source?.details.est_energy_consumption || '',
        max_allowed_temperature: data.batch_source?.details.max_allowed_temperature || '',
        progress: data.batch_source?.details.progress || '',
        recipe: data.batch_source?.details.recipe || '',
        total_grinding_time: data.batch_source?.details.total_grinding_time || ''
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.batch_details_modal.title`)}
            subTitle={t(`${labelAmend}.batch_details_modal.subtitle`)}
            maxWidth="1062px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="batch_id"
                        label={t(`${labelAmend}.batch_details_modal.id_address`)}
                        margin="none"
                        size="small"
                        name="batch_id"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.batch_id)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="batch_recipe"
                        label={t(`${labelAmend}.batch_details_modal.recipe_address`)}
                        margin="none"
                        size="small"
                        name="recipe"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.recipe)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('max_allowed_temperature')}
                        helperText={getHelperText('max_allowed_temperature')}
                        fullWidth
                        id="max_allowed_temperature"
                        label={t(`${labelAmend}.batch_details_modal.maximum_temperature`)}
                        margin="none"
                        size="small"
                        name="max_allowed_temperature"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.max_allowed_temperature)}
                        required
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="batch_size"
                        label={t(`${labelAmend}.batch_details_modal.size_address`)}
                        margin="none"
                        size="small"
                        name="batch_size"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.batch_size)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="progress"
                        label={t(`${labelAmend}.batch_details_modal.progress_address`)}
                        margin="none"
                        size="small"
                        name="progress"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.progress)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="energy_consumption"
                        label={t(`${labelAmend}.batch_details_modal.grinding_energy`)}
                        margin="none"
                        size="small"
                        name="est_energy_consumption"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.est_energy_consumption)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        fullWidth
                        id="current_cycles"
                        label={t(`${labelAmend}.batch_details_modal.cycles_address`)}
                        margin="none"
                        size="small"
                        name="cycles"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.cycles)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('total_grinding_time')}
                        helperText={getHelperText('total_grinding_time')}
                        fullWidth
                        id="total_grinding_time"
                        label={t(`${labelAmend}.batch_details_modal.total_time_address`)}
                        margin="none"
                        size="small"
                        name="total_grinding_time"
                        onBlur={handleBlur}
                        onChange={handleChangeAddress(handleChange)}
                        value={getDefaultFieldValue(values.total_grinding_time)}
                        required
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditBatchRecords.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: T.shape({
    batch_source: T.shape({
      details: T.shape({
        batch_id: T.string,
        recipe: T.string,
        max_allowed_temperature: T.string,
        batch_size: T.string,
        progress: T.string,
        est_energy_consumption: T.string,
        cycles: T.string,
        total_grinding_time: T.string
      }),
      status: T.shape({
        complete: T.shape({
          value: T.string,
          address: T.string
        }),
        cancelled: T.shape({
          value: T.string,
          address: T.string
        }),
        in_operation: T.shape({
          value: T.string,
          address: T.string
        })
      }),
      target: T.shape({
        type: T.string.isRequired,
        time: T.shape({
          hours: T.string,
          minutes: T.string,
          value: T.number
        }),
        grinding_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        specific_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        cycles: T.shape({
          target: T.string,
          value: T.number
        })
      })
    })
  })
};

EditBatchRecords.defaultProps = {
  machineId: null,
  data: null
};

export default EditBatchRecords;

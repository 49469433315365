import React from 'react';
import { Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from 'helpers/utils';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { IrisBatchMeasurementsSchema } from '../../../../../attrs/formValidation';
import { IrisBatchMeasurementsPropTypes } from '../../../../../helpers/propTypes';
import { getSelectedIrisBatchMeasurements } from '../../../../../redux/machines_v2/selectors';
import { updateIrisBatchMeasurements } from '../../../../../redux/machines_v2/actions';

const labelAmend = 'machines.machine_details.batch_iris.measurements';

const IrisBatchRecordsEditMeasurements = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getSelectedIrisBatchMeasurements);

  function isSaving() {
    return isLoading(loadingState.status);
  }

  const submitForm = (values, formikHelpers) => {
    dispatch(updateIrisBatchMeasurements(machineId, values));
    handleClose(true);
    formikHelpers.resetForm();
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`default_actions.save`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeValue = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={IrisBatchMeasurementsSchema}
      initialValues={{
        progress_variable: getDefaultFieldValue(data.progress_variable),
        total_grinding_time_variable: getDefaultFieldValue(data.total_grinding_time_variable),
        grinding_energy_variable: getDefaultFieldValue(data.grinding_energy_variable),
        specific_energy_variable: getDefaultFieldValue(data.specific_energy_variable),
        estimated_finish_time_variable: getDefaultFieldValue(data.estimated_finish_time_variable),
        current_cycles_variable: getDefaultFieldValue(data.current_cycles_variable),
        number_of_passes_variable: getDefaultFieldValue(data.number_of_passes_variable),
        total_grinding_energy_variable: getDefaultFieldValue(data.total_grinding_energy_variable)
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.modal_title`)}
            subTitle={t(`${labelAmend}.modal_subtitle`)}
            maxWidth="978px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('progress_variable')}
                        helperText={getHelperText('progress_variable')}
                        fullWidth
                        id="progress_variable"
                        label={t(`${labelAmend}.progress_variable`)}
                        margin="none"
                        size="small"
                        name="progress_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.progress_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('total_grinding_time_variable')}
                        helperText={getHelperText('total_grinding_time_variable')}
                        fullWidth
                        id="total_grinding_time_variable"
                        label={t(`${labelAmend}.total_grinding_time_variable`)}
                        margin="none"
                        size="small"
                        name="total_grinding_time_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.total_grinding_time_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('grinding_energy_variable')}
                        helperText={getHelperText('grinding_energy_variable')}
                        fullWidth
                        id="grinding_energy_variable"
                        label={t(`${labelAmend}.grinding_energy_variable`)}
                        margin="none"
                        size="small"
                        name="grinding_energy_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.grinding_energy_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('specific_energy_variable')}
                        helperText={getHelperText('specific_energy_variable')}
                        fullWidth
                        id="specific_energy_variable"
                        label={t(`${labelAmend}.specific_energy_variable`)}
                        margin="none"
                        size="small"
                        name="specific_energy_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.specific_energy_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('estimated_finish_time_variable')}
                        helperText={getHelperText('estimated_finish_time_variable')}
                        fullWidth
                        id="estimated_finish_time_variable"
                        label={t(`${labelAmend}.estimated_finish_time_variable`)}
                        margin="none"
                        size="small"
                        name="estimated_finish_time_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.estimated_finish_time_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('current_cycles_variable')}
                        helperText={getHelperText('current_cycles_variable')}
                        fullWidth
                        id="current_cycles_variable"
                        label={t(`${labelAmend}.current_cycles_variable`)}
                        margin="none"
                        size="small"
                        name="current_cycles_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.current_cycles_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('number_of_passes_variable')}
                        helperText={getHelperText('number_of_passes_variable')}
                        fullWidth
                        id="number_of_passes_variable"
                        label={t(`${labelAmend}.number_of_passes_variable`)}
                        margin="none"
                        size="small"
                        name="number_of_passes_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.number_of_passes_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('total_grinding_energy_variable')}
                        helperText={getHelperText('total_grinding_energy_variable')}
                        fullWidth
                        id="total_grinding_energy_variable"
                        label={t(`${labelAmend}.total_grinding_energy_variable`)}
                        margin="none"
                        size="small"
                        name="total_grinding_energy_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.total_grinding_energy_variable)}
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

IrisBatchRecordsEditMeasurements.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: IrisBatchMeasurementsPropTypes
};

IrisBatchRecordsEditMeasurements.defaultProps = {
  machineId: null,
  data: null
};

export default IrisBatchRecordsEditMeasurements;

import React, { useEffect, useState } from 'react';
import { Badge, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';
import clsx from 'clsx';
import T from 'prop-types';
import { MenuUseStyles } from './Menu.styled';

const MenuComponent = ({ menuItems, onMenuSelect, initializeSelected }) => {
  const classes = MenuUseStyles();
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState(initializeSelected);

  useEffect(() => {
    if (initializeSelected) {
      setActiveItem(initializeSelected);
    }
  }, [initializeSelected]);

  /**
   * Get the item key, if key is not available, then use text as the key
   * @param {{key: string, text: string}} item
   * @return {string}
   */
  const getItemKey = item => item.key || item.text;

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  /**
   * Handle menu item click to set as active, execute the component action and the menu action if available
   * @param item
   */
  const handleMenuItemClick = item => {
    if (!item.notClickable) {
      if (typeof item.action === 'function') {
        item.action();
      }
      onMenuSelect(item);
      setActiveItem(getItemKey(item));
    }
  };

  const hasBadge = item => item.badge !== null && item.badge !== undefined;

  return (
    <div
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
    >
      <div className={classes.collapseContainer}>
        <IconButton onClick={handleToggleDrawer} className={clsx(classes.collapseIcon, classes.iconColor)}>
          {open ? <DoubleArrow style={{ transform: 'rotate(180deg)' }} /> : <DoubleArrow />}
        </IconButton>
      </div>
      <List>
        {menuItems
          .filter(item => item.show !== false)
          .map(item => (
            <React.Fragment key={getItemKey(item)}>
              {item.topDivider && <Divider className={classes.divider} />}
              <ListItemButton
                data-testid={`menu-item-${getItemKey(item)}`}
                onClick={() => handleMenuItemClick(item)}
                className={clsx(classes.listItem, { [classes.activeItem]: activeItem === getItemKey(item) })}
                classes={{ root: !open && classes.listItemClosed }}
                disabled={item.notClickable}
              >
                {!item.isCategory && item.icon && (
                  <Tooltip
                    title={`${item.text}${hasBadge(item) ? ` (${item.badge})` : ''}`}
                    placement="right"
                    disableHoverListener={open}
                  >
                    <ListItemIcon
                      className={clsx(classes.iconColor, {
                        [classes.iconClosed]: !open
                      })}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </Tooltip>
                )}
                <ListItemText
                  primary={!open && item.smallText ? item.smallText : item.text}
                  className={clsx({
                    [classes.categoryText]: item.isCategory,
                    [classes.categoryTextActive]: item.isCategory && activeItem === getItemKey(item),
                    [classes.listItemTextClosed]: !open && !item.isCategory,
                    [classes.listItemTextClosedCategory]: !open && item.isCategory,
                    [classes.listItemTextOpen]: open || item.isCategory
                  })}
                />
                {hasBadge(item) && (open || item.isCategory) && (
                  <Badge
                    badgeContent={`${item.badge}`}
                    color="primary"
                    className={clsx(classes.badge, {
                      [classes.badgeClosed]: !open,
                      [classes.badgeOpen]: open,
                      [classes.badgeNoBackground]: item.isCategory && activeItem !== getItemKey(item),
                      [classes.categoryTextActive]: item.isCategory && activeItem === getItemKey(item),
                      [classes.badgeWarning]: item.badgeWarning,
                      [classes.badgeActive]: activeItem === getItemKey(item)
                    })}
                  />
                )}
              </ListItemButton>
            </React.Fragment>
          ))}
      </List>
    </div>
  );
};

MenuComponent.propTypes = {
  menuItems: T.arrayOf(
    T.shape({
      text: T.string.isRequired,
      key: T.string,
      icon: T.element,
      badge: T.oneOfType([T.string, T.number]),
      isCategory: T.bool,
      notClickable: T.bool,
      topDivider: T.bool,
      action: T.func,
      smallText: T.string,
      show: T.bool
    })
  ).isRequired,
  onMenuSelect: T.func.isRequired,
  initializeSelected: T.string
};

MenuComponent.defaultProps = {
  initializeSelected: ''
};

export default MenuComponent;

import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { Grid } from '@mui/material';

import { FormElements, NotifyIcon } from 'web-components';
import { useDispatch, useSelector } from 'react-redux';
import { resetCommissionNumberStatus, verifyCommissionNumber } from 'redux/machines/actions';
import { getVerification, getVerificationCommissionNumber } from 'redux/machines/selectors';
import { StatusChip, VerifyButton } from './elements';
import { COLOR_PROGRESS, COLOR_WARNING } from '../../../../attrs/colors';
import { STATUS_INVALID, STATUS_NOT_VERIFIED, STATUS_VALID } from '../../../../attrs/salesforce';
import { isLoading } from '../../../../helpers/utils';
import { StyledProgressIcon } from '../../Customers/FormElements/elements';

const CommissionNumberFormElement = ({
  handleBlur,
  handleChange,
  values,
  errors,
  handleSubmit,
  touched,
  submitDisabled
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const verificationStatus = useSelector(getVerificationCommissionNumber);
  const { loadingState } = useSelector(getVerification);

  const handleVerifyButtonClick = () => {
    if (Object.keys(touched).length === 1 && touched.commission_number && !submitDisabled) {
      handleSubmit();
    } else {
      dispatch(verifyCommissionNumber(values.commission_number));
    }
  };

  const handleTextFileChange = value => {
    dispatch(resetCommissionNumberStatus(STATUS_NOT_VERIFIED));
    handleChange(value);
  };

  const isLoadingState = isLoading(loadingState.status);
  const shouldShowStatusChip = verificationStatus === STATUS_INVALID || isLoadingState;

  const helperText = errors.commission_number
    ? t(`form.validate.required`)
    : t('machines.form.commission_number_subtitle');

  const isButtonDisabled = isLoadingState || !values.commission_number || Boolean(errors.commission_number);

  const verificationMessage = {
    true: '',
    [verificationStatus === STATUS_INVALID]: t('machines.form.commission_number_verification_failed'),
    [verificationStatus === STATUS_NOT_VERIFIED && isLoadingState]: t(
      'machines.form.commission_number_verification_progress'
    )
  }.true;

  const endAdornment = {
    true: <></>,
    [verificationStatus === STATUS_VALID]: (
      <NotifyIcon iconName="successFilled" style={{ margin: '26px -5px 0 0' }} data-testid="success-icon" />
    ),
    [isLoadingState]: <StyledProgressIcon size={14} />
  }.true;

  return (
    <>
      <Grid container wrap="nowrap">
        <FormElements.TextField
          label={t('machines.form.commission_number')}
          fullWidth
          disabled={isLoadingState}
          id="commission_number"
          margin="normal"
          name="commission_number"
          data-testid="commission_number"
          onChange={handleTextFileChange}
          onBlur={handleBlur}
          value={values.commission_number}
          error={errors.commission_number}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          placeholder={t('machines.form.commission_number')}
          required
          InputProps={{ endAdornment }}
        />
        <VerifyButton
          data-testid="commission-number-verify"
          color="primary"
          disabled={isButtonDisabled}
          onClick={handleVerifyButtonClick}
        >
          {t('form.verify')}
        </VerifyButton>
      </Grid>
      {shouldShowStatusChip && (
        <StatusChip isProgress={isLoadingState} data-testid="status_commission_number">
          {isLoadingState ? (
            <NotifyIcon iconName="clock" style={{ color: COLOR_PROGRESS, fontSize: '1.5rem' }} />
          ) : (
            <NotifyIcon iconName="warning" style={{ color: COLOR_WARNING, fontSize: '1.625rem' }} />
          )}
          <span>{verificationMessage}</span>
        </StatusChip>
      )}
    </>
  );
};

CommissionNumberFormElement.propTypes = {
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    commission_number: T.string,
    commission_number_status: T.bool
  }).isRequired,
  errors: T.shape({
    commission_number: T.bool
  }).isRequired,
  handleSubmit: T.func.isRequired,
  touched: T.shape({
    commission_number: T.bool
  }).isRequired,
  submitDisabled: T.bool.isRequired
};

export default CommissionNumberFormElement;

import React from 'react';
import { Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from 'helpers/utils';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { IrisBatchTargetSchema } from '../../../../../attrs/formValidation';
import { IrisBatchTargetPropTypes } from '../../../../../helpers/propTypes';
import { getSelectedIrisBatchTarget } from '../../../../../redux/machines_v2/selectors';
import { updateIrisBatchTarget } from '../../../../../redux/machines_v2/actions';

const labelAmend = 'machines.machine_details.batch_iris.target';

const IrisBatchRecordsEditTarget = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getSelectedIrisBatchTarget);

  function isSaving() {
    return isLoading(loadingState.status);
  }

  const submitForm = (values, formikHelpers) => {
    dispatch(updateIrisBatchTarget(machineId, values));
    handleClose(true);
    formikHelpers.resetForm();
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`default_actions.save`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeValue = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={IrisBatchTargetSchema}
      initialValues={{
        target_type_variable: getDefaultFieldValue(data.target_type_variable),
        target_value_variable: getDefaultFieldValue(data.target_value_variable),
        target_comment_1: getDefaultFieldValue(data.target_comment_1),
        target_comment_2: getDefaultFieldValue(data.target_comment_2),
        target_comment_3: getDefaultFieldValue(data.target_comment_3),
        target_comment_4: getDefaultFieldValue(data.target_comment_4)
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.modal_title`)}
            subTitle={t(`${labelAmend}.modal_subtitle`)}
            maxWidth="648px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_type_variable')}
                        helperText={getHelperText('target_type_variable')}
                        fullWidth
                        id="target_type_variable"
                        label={t(`${labelAmend}.target_type_variable`)}
                        margin="none"
                        size="small"
                        name="target_type_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_type_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_value_variable')}
                        helperText={getHelperText('target_value_variable')}
                        fullWidth
                        id="target_value_variable"
                        label={t(`${labelAmend}.target_value_variable`)}
                        margin="none"
                        size="small"
                        name="target_value_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_value_variable)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_comment_1')}
                        helperText={getHelperText('target_comment_1')}
                        fullWidth
                        id="target_comment_1"
                        label={t(`${labelAmend}.target_comment_1`)}
                        margin="none"
                        size="small"
                        name="target_comment_1"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_comment_1)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_comment_2')}
                        helperText={getHelperText('target_comment_2')}
                        fullWidth
                        id="target_comment_2"
                        label={t(`${labelAmend}.target_comment_2`)}
                        margin="none"
                        size="small"
                        name="target_comment_2"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_comment_2)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_comment_3')}
                        helperText={getHelperText('target_comment_3')}
                        fullWidth
                        id="target_comment_3"
                        label={t(`${labelAmend}.target_comment_3`)}
                        margin="none"
                        size="small"
                        name="target_comment_3"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_comment_3)}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        error={getError('target_comment_4')}
                        helperText={getHelperText('target_comment_4')}
                        fullWidth
                        id="target_comment_4"
                        label={t(`${labelAmend}.target_comment_4`)}
                        margin="none"
                        size="small"
                        name="target_comment_4"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.target_comment_4)}
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

IrisBatchRecordsEditTarget.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: IrisBatchTargetPropTypes
};

IrisBatchRecordsEditTarget.defaultProps = {
  machineId: null,
  data: null
};

export default IrisBatchRecordsEditTarget;

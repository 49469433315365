import { Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyTypo } from 'web-components';
import { updateIrisTableNames } from '../../../../../redux/machines_v2/actions';
import { StyledHandlers, StyledNewDialog } from '../elements';
import { getFormikError, getFormikHelperText, isLoading } from '../../../../../helpers/utils';
import { DataBaseTableNameSchema } from '../../../../../attrs/formValidation';
import { getSelectedIrisTableNames } from '../../../../../redux/machines_v2/selectors';
import { IrisTableNameEnum } from 'attrs/machines';

const labelAmend = 'machines.machine_details';

const EditDatabaseTableName = ({ open, machineId, handleClose, currentTableName }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getSelectedIrisTableNames);

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const title =
    currentTableName.type === IrisTableNameEnum.BATCH_MEASUREMENTS_TABLE_NAME
      ? t(`${labelAmend}.edit_database_table_name.measurements_title`)
      : t(`${labelAmend}.edit_database_table_name.title`);

  const subtitle =
    currentTableName.type === IrisTableNameEnum.BATCH_MEASUREMENTS_TABLE_NAME
      ? t(`${labelAmend}.edit_database_table_name.measurements_subtitle`)
      : t(`${labelAmend}.edit_database_table_name.subtitle`);

  const submitForm = (values, formikHelpers) => {
    const payload = {
      table_name: values.table_name,
      target: currentTableName.type
    };
    try {
      dispatch(updateIrisTableNames(machineId, payload));
      handleCloseWarningModal();
      handleClose();
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBarWarningModal = (errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => {
          handleClose();
          setOpenWarningModal(true);
        }}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t('default_actions.save')}
      </Button>
    </StyledHandlers>
  );

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          handleCloseWarningModal();
          handleClose();
          resetForm();
        }}
      >
        {t('default_actions.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="status-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t('default_actions.yes_edit')}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        table_name: currentTableName?.database || ''
      }}
      validationSchema={DataBaseTableNameSchema}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <>
            <StyledNewDialog
              open={open}
              handleClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleClose();
              }}
              title={title}
              subTitle={subtitle}
              maxWidth="464px"
              maxHeight="204px"
              displayActionBar="true"
              actionBar={
                currentTableName.hasWarning
                  ? actionBarWarningModal(errors, resetForm, dirty)
                  : actionBar(handleSubmit, errors, resetForm, dirty)
              }
              shouldCloseOnOverlayClick={false}
              content={
                <Grid container spacing={2} columns={12} sx={{ maxWidth: '1062px' }}>
                  <Grid item xs={12}>
                    <FormElements.TextField
                      fullWidth
                      error={getError('table_name')}
                      helperText={getHelperText('table_name')}
                      id="table_name"
                      label={t(`${labelAmend}.edit_database_table_name.form.table_name`)}
                      margin="normal"
                      name="table_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.table_name}
                    />
                  </Grid>
                </Grid>
              }
            />
            <StyledNewDialog
              open={openWarningModal}
              handleClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                handleCloseWarningModal();
              }}
              title={t(`${labelAmend}.edit_database_table_name.title`)}
              subTitle={t(`${labelAmend}.edit_database_table_name.warning_subtitle`)}
              maxWidth="688px"
              maxHeight="311px"
              displayActionBar="true"
              actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
              shouldCloseOnOverlayClick={false}
              content={
                <Grid container spacing={2} columns={12} sx={{ maxWidth: '1062px' }}>
                  <Grid item xs={12}>
                    <NotifyTypo.Body1>{t(`${labelAmend}.edit_database_table_name.warning_text`)}</NotifyTypo.Body1>
                  </Grid>
                </Grid>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

EditDatabaseTableName.propTypes = {
  currentTableName: T.shape({
    title: T.string,
    subtitle: T.string,
    database: T.string,
    hasWarning: T.bool,
    type: T.string
  }).isRequired,
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditDatabaseTableName.defaultProps = {
  machineId: null
};

export default EditDatabaseTableName;

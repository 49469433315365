import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { getInitials } from 'helpers/utils';
import { STATUS_INVALID, STATUS_VALID } from 'attrs/salesforce';
import { NotifyIcon } from 'web-components';
import PersonIcon from '@mui/icons-material/Person';

export default function GatewayCustomer({ gatewayDetails }) {
  const { t } = useTranslation();

  const getSAPStatus = statusParam =>
    statusParam === STATUS_VALID ? (
      <NotifyIcon iconName="successFilled" fontSize="medium" style={{ marginTop: '3px' }} data-testid="success-icon" />
    ) : (
      <>
        {statusParam === STATUS_INVALID ? (
          <NotifyIcon
            iconName="checkmarkFilled"
            fontSize="medium"
            style={{ marginTop: '8px' }}
            data-testid="warning-icon"
          />
        ) : null}
      </>
    );

  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 2,
        borderRadius: '8px',
        width: '100%',
        p: 3
      }}
      data-testid="gateway-customer"
    >
      <Stack direction="column" spacing={3}>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{t('gateway_details.customer.title')}</Typography>
        <Stack direction="row" spacing={4}>
          <Avatar
            key={`item-avatar-${'id'}`}
            alt={`${gatewayDetails?.organization_name}`}
            src={`${gatewayDetails?.organization_logo}`}
            sx={{
              fontSize: 12,
              width: 44,
              height: 44,
              boxShadow: 3,
              ...(!gatewayDetails?.organization_logo && {
                color: '#58BAAA',
                bgcolor: '#CEEAE5',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#DDDDDD80'
              })
            }}
            data-testid="gateway-customer-avatar"
          >
            {!gatewayDetails?.organization_name ? (
              <PersonIcon />
            ) : (
              <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: '550' }}>
                {getInitials(`${gatewayDetails?.organization_name}`)}
              </Typography>
            )}
          </Avatar>
          <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
            <Typography sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
              {t('gateway_details.customer.customer_name')}
            </Typography>
            <Typography sx={{ fontSize: 16 }} data-testid="gateway-customer-organization-name" gutterBottom>
              {getValueToNullableCell(gatewayDetails?.organization_name)}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} justifyContent="flex-start" alignItems="flex-start">
            <Typography sx={{ fontSize: 12, color: '#7A7D85' }} gutterBottom>
              {t('gateway_details.customer.sap_id')}
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Typography sx={{ fontSize: 16 }} data-testid="gateway-customer-organization-sap-account" gutterBottom>
                {getValueToNullableCell(gatewayDetails?.organization_sap_account)}
              </Typography>
              {getSAPStatus(gatewayDetails?.organization_sap_status)}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

GatewayCustomer.propTypes = {
  gatewayDetails: T.shape().isRequired
};

import React, { useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@mui/material';
import { Formik } from 'formik';

import { SensorDataSchema } from 'attrs/formValidation';
import { getFormikError, getFormikHelperText, getSensorPropsFromType } from 'helpers/utils';
import { NotifyTypo } from 'web-components';

import SensorConfigurationRadioGroup from './fields/SensorConfigurationRadioGroup';
import BaseConfigurationFields from './fields/BaseConfigurationFields';
import EngineeringUnitsFields from './fields/EngineeringUnitsFields';
import TriggerWarningFields from './fields/TriggerWarningFields';
import YAxisFields from './fields/YAxisFields';
import { initialSensorValues } from '../../../../../attrs/sensorConfig';

import { StyledCard, StyledCardContent, StyledCardFormAction, StyledCardHeader } from '../elements';

const NewSensorForm = ({ onDelete, onSubmit, availableSensorTypes, isNewSensor }) => {
  const { t } = useTranslation();
  const [engineeringDataType, setEngineeringDataType] = useState(0);

  return (
    <Formik
      initialValues={initialSensorValues}
      onSubmit={values => onSubmit(values)}
      validationSchema={SensorDataSchema}
      enableReinitialize
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values // Values represents metric value
      }) => {
        const handleChangeSensorType = e => {
          const { unit, period } = getSensorPropsFromType(e.target.value);
          setFieldValue('type', e.target.value);
          setFieldValue('units', unit);
          setFieldValue('period', period);
        };

        const handleChangeEngineeringDataType = (e, value) => {
          if (value === 0) {
            setFieldValue('range.min.value', undefined);
            setFieldValue('range.max.value', undefined);
          } else {
            setFieldValue('range.min.source', null);
            setFieldValue('range.max.source', null);
          }
          setEngineeringDataType(value);
        };

        const getError = getFormikError({ errors, touched });
        const getHelperText = getFormikHelperText({ errors, t });

        return (
          <StyledCard>
            <StyledCardContent>
              <StyledCardHeader>
                <NotifyTypo.Heading2>{t('machines.form.sensor.title')}</NotifyTypo.Heading2>
              </StyledCardHeader>
              <Container maxWidth="sm">
                <SensorConfigurationRadioGroup
                  values={values}
                  isNewSensor={isNewSensor}
                  setFieldValue={setFieldValue}
                />
                <BaseConfigurationFields
                  values={values}
                  availableSensorTypes={availableSensorTypes}
                  isNewSensor
                  handleChangeSensorType={handleChangeSensorType}
                  getError={getError}
                  getHelperText={getHelperText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <EngineeringUnitsFields
                  values={values}
                  engineeringDataType={engineeringDataType}
                  getError={getError}
                  getHelperText={getHelperText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeTab={handleChangeEngineeringDataType}
                />
                <TriggerWarningFields
                  values={values}
                  setFieldValue={setFieldValue}
                  getError={getError}
                  getHelperText={getHelperText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <YAxisFields
                  values={values}
                  getError={getError}
                  getHelperText={getHelperText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <StyledCardFormAction>
                  <Button color="secondary" onClick={onDelete} variant="text" type="button">
                    {t('form.delete')}
                  </Button>
                  <Button
                    data-testid="machine-details--add-sensor-button"
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                    onClick={handleSubmit}
                    variant="contained"
                    type="button"
                  >
                    {t('form.add')}
                  </Button>
                </StyledCardFormAction>
              </Container>
            </StyledCardContent>
          </StyledCard>
        );
      }}
    </Formik>
  );
};

NewSensorForm.propTypes = {
  onDelete: T.func.isRequired,
  onSubmit: T.func.isRequired,
  availableSensorTypes: T.arrayOf(T.shape({})).isRequired,
  isNewSensor: T.bool.isRequired
};

export default NewSensorForm;

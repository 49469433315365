import { Box, Button, CircularProgress, FormControl, Stack } from '@mui/material';
import { Formik } from 'formik';
import T, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements, NotifyIcon, NotifyTypo } from 'web-components';
import { updateBatchStatus } from 'redux/batch/actions';
import { getBatchReducerState } from 'redux/rootSelectors';
import { getFormikError, getFormikHelperText, isLoading, validateBlankSpace } from 'helpers/utils';
import { useSnackbar } from 'notistack';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { BatchStatusSchema } from '../../../../../attrs/formValidation';

const labelAmend = 'machines.machine_details';

const EditBatchRecordsStatus = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { updateBatchStatusStatus } = useSelector(getBatchReducerState);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  function isSaving() {
    return isLoading(updateBatchStatusStatus.status);
  }

  const submitForm = (values, formikHelpers) => {
    // This workaround is here to prevent error from put, waiting for backend to fix it in a few years
    const payload = values;
    payload.complete.value = values.complete.value || '0';
    payload.cancelled.value = values.cancelled.value || '0';
    try {
      dispatch(updateBatchStatus(machineId, values));
      handleClose(true);
      formikHelpers.resetForm();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.generic_error'), errorToastProps);
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const getDefaultByAddress = (address, value) => (address ? value || '' : '');

  const handleChangeAddress = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={BatchStatusSchema}
      initialValues={{
        cancelled: {
          address: data?.batch_source?.status?.cancelled?.address || '',
          value: getDefaultByAddress(
            data?.batch_source?.status?.cancelled?.address,
            data?.batch_source?.status?.cancelled?.value
          )
        },
        complete: {
          address: data?.batch_source?.status?.complete?.address || '',
          value: getDefaultByAddress(
            data?.batch_source?.status?.complete?.address,
            data?.batch_source?.status?.complete?.value
          )
        },
        in_operation: {
          address: data?.batch_source?.status?.in_operation?.address || '',
          value: getDefaultByAddress(
            data?.batch_source?.status?.in_operation?.address,
            data?.batch_source?.status?.in_operation?.value
          )
        }
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.status_modal.title`)}
            subTitle={t(`${labelAmend}.status_modal.subtitle`)}
            maxWidth="1062px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <Stack direction="column" gap={4}>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <NotifyIcon iconName="batchComplete" fontSize="medium" />
                        <NotifyTypo.Body1 fontWeight="bold" sx={{ mb: 1 }}>
                          {t(`${labelAmend}.batch_status_label.completed`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <StyledContentGrid>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('complete.address')}
                            helperText={getHelperText('complete.address')}
                            fullWidth
                            id="completed_status"
                            label={`${t(`${labelAmend}.status_modal.address`)}`}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="complete.address"
                            value={values.complete.address}
                            size="small"
                            margin="none"
                          />
                        </StyledGridItem>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('complete.value')}
                            helperText={getHelperText('complete.value')}
                            fullWidth
                            id="completed_status_value"
                            label={`${t(`${labelAmend}.status_modal.value`)}`}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="complete.value"
                            value={values.complete.value}
                            size="small"
                            margin="none"
                          />
                        </StyledGridItem>
                      </StyledContentGrid>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <NotifyIcon iconName="batchError" fontSize="medium" />
                        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1, mb: 1 }}>
                          {t(`${labelAmend}.batch_status_label.cancelled`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <StyledContentGrid>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('cancelled.address')}
                            helperText={getHelperText('cancelled.address')}
                            fullWidth
                            id="cancelled_status"
                            label={`${t(`${labelAmend}.status_modal.address`)}`}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="cancelled.address"
                            value={values.cancelled.address}
                            size="small"
                            margin="none"
                          />
                        </StyledGridItem>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('cancelled.value')}
                            helperText={getHelperText('cancelled.value')}
                            fullWidth
                            id="cancelled_status_value"
                            label={`${t(`${labelAmend}.status_modal.value`)}`}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="cancelled.value"
                            value={values.cancelled.value}
                            size="small"
                            margin="none"
                          />
                        </StyledGridItem>
                      </StyledContentGrid>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <NotifyIcon iconName="batchPending" fontSize="medium" />
                        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1, mb: 1 }}>
                          {t(`${labelAmend}.batch_status_label.in_operation`)}
                        </NotifyTypo.Body1>
                      </Box>
                      <StyledContentGrid>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('in_operation.address')}
                            helperText={getHelperText('in_operation.address')}
                            fullWidth
                            id="in_operation_status"
                            label={t(`${labelAmend}.status_modal.address`)}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="in_operation.address"
                            value={values.in_operation.address}
                            size="small"
                            margin="none"
                            required
                          />
                        </StyledGridItem>
                        <StyledGridItem>
                          <FormElements.TextField
                            error={getError('in_operation.value')}
                            helperText={getHelperText('in_operation.value')}
                            fullWidth
                            id="in_operation_status_value"
                            label={t(`${labelAmend}.status_modal.value`)}
                            onBlur={handleBlur}
                            onChange={handleChangeAddress(handleChange)}
                            name="in_operation.value"
                            value={values.in_operation.value}
                            size="small"
                            margin="none"
                            required
                          />
                        </StyledGridItem>
                      </StyledContentGrid>
                    </Box>
                  </Stack>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditBatchRecordsStatus.propTypes = {
  data: T.shape({
    batch_source: T.shape({
      details: T.shape({
        batch_id: T.string,
        recipe: T.string,
        max_allowed_temperature: T.string,
        batch_size: T.string,
        progress: T.string,
        est_energy_consumption: T.string,
        cycles: T.string,
        total_grinding_time: T.string
      }),
      status: T.shape({
        complete: T.shape({
          value: T.string,
          address: T.string
        }),
        cancelled: T.shape({
          value: T.string,
          address: T.string
        }),
        in_operation: T.shape({
          value: T.string,
          address: T.string
        })
      }),
      target: T.shape({
        type: T.string.isRequired,
        time: T.shape({
          hours: T.string,
          minutes: T.string,
          value: T.number
        }),
        grinding_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        specific_energy: T.shape({
          target: T.string,
          value: T.number
        }),
        cycles: T.shape({
          target: T.string,
          value: T.number
        })
      })
    })
  }),
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired
};

EditBatchRecordsStatus.defaultProps = {
  data: null,
  machineId: null
};

export default EditBatchRecordsStatus;

import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { getGatewaysAssignable } from 'redux/gateways/selectors';
import { loadGatewaysAssignable } from 'redux/gateways/actions';
import { Button } from '@mui/material';
import GatewayTableSelectorAssignable from 'components/GatewayTable/GatewayTableAssignabled/GatewayAssignabled';
import { useSnackbar } from 'notistack';
import { getSelectedOrganizationId } from 'redux/organizations/selectors';
import { StyledContent, StyledNewDialog } from './elements';
import { StyledHandlers } from '../Licenses/elements';
import { gatewayV2BindOrganizationAction } from '../../../../redux/gateways_v2/actions';

const labelAmmend = 'machines.machine_details.gateway_modal';

const AddGateway = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSelector(getGatewaysAssignable);
  const organizationId = useSelector(getSelectedOrganizationId);
  const [selectedGateway, setSelectedGateway] = useState({});

  const handleCloseClear = () => {
    handleClose();
    setSelectedGateway({});
  };

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  useEffect(() => {
    dispatch(loadGatewaysAssignable());
  }, [dispatch]);

  const handleLinkGateway = () => {
    try {
      dispatch(
        gatewayV2BindOrganizationAction({
          gatewayId: selectedGateway.serial_number,
          organizationId
        })
      );
      handleClose(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        enqueueSnackbar(t('machines.error_create_clone'), errorToastProps);
      }
    }
  };

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={handleCloseClear}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="license-modal-submit"
        onClick={handleLinkGateway}
        disabled={Object.keys(selectedGateway).length === 0}
        style={{ padding: '1rem 2.5rem' }}
      >
        {t(`${labelAmmend}.gateway_button_action`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      open={open}
      handleClose={handleCloseClear}
      title={t(`${labelAmmend}.gateway_title`)}
      subTitle={t(`${labelAmmend}.gateway_subtitle_customer`)}
      displayActionBar="true"
      actionBar={actionBar}
      maxWidth="1062px"
      content={
        <StyledContent>
          {data && (
            <GatewayTableSelectorAssignable
              rows={data}
              setSelectedGateway={setSelectedGateway}
              selectedGateway={selectedGateway}
            />
          )}
        </StyledContent>
      }
    />
  );
};

AddGateway.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired
};

export default AddGateway;

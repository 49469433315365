import { Breadcrumbs, Button, Container, Divider, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { gatewaysV2GetByIdAction } from 'redux/gateways_v2/actions';
import { getGatewaysV2 } from 'redux/rootSelectors';
import { useHistory } from 'react-router-dom';
import UnLinkGateway from 'components/CustomerDetails/GatewayTable/UnLinkGateway/UnLinkGatewayModal';
import GatewayInformation from './GatewayInformation';
import GatewayCustomer from './GatewayCustomer';
import GatewayMachines from './GatewayMachines';
import GatewayVersions from './GatewayVersions';
import GatewayNetwork from './GatewayNetwork';
import GatewayLogs from './GatewayLogs';

export default function GatewayDetailsContainer() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const machineDetailsEqual = (oldObj, newObj) =>
    JSON.stringify(oldObj?.getGatewayById || '') === JSON.stringify(newObj?.getGatewayById || '');
  const { getGatewayById: gatewayDetails } = useSelector(getGatewaysV2, machineDetailsEqual);

  const [unlinkModalParam, setUnlinkModalParam] = useState({
    open: false,
    row: {
      gatewayId: '',
      organizationId: ''
    }
  });

  const handleCloseUnlinkGateway = () =>
    setUnlinkModalParam({
      open: false,
      row: {
        gatewayId: '',
        organizationId: ''
      },
      goesTo: ''
    });

  const unLink = () => {
    setUnlinkModalParam({
      open: true,
      row: {
        gatewayId: id,
        organizationId: gatewayDetails?.organization_id
      },
      goesTo: '/gateways'
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(
        gatewaysV2GetByIdAction({
          gatewayId: id
        })
      );
    }
  }, [dispatch, gatewaysV2GetByIdAction, id]);

  return (
    <Container maxWidth={false} className="default-inner-container-with-breadcrumb" data-testid="gateway-details">
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Button
            variant="text"
            startIcon={<ArrowBackIosRoundedIcon />}
            sx={{ fontSize: 14, p: 0, pt: '2px' }}
            onClick={() => history.goBack()}
            data-testid="gateway-details-btn-back"
          >
            {t('default_actions.back')}
          </Button>
          <Divider orientation="vertical" flexItem />
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="primary.main"
              sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
              href="/gateways"
              data-testid="gateway-details-link-gateways"
            >
              {t('gateway_details.breadcrumb.gateways')}
            </Link>
            <Typography component="span" color="text.primary" sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}>
              {t('gateway_details.breadcrumb.gateway')}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
          <Stack spacing={1}>
            <Typography component="span" sx={{ fontSize: 20, fontWeight: 'bold' }}>
              {t('gateway_details.title', { gatewayId: id })}
            </Typography>
            <Typography component="span" color="text.secondary" sx={{ fontSize: 14 }}>
              {t('gateway_details.caption')}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            sx={{ height: '56px' }}
            startIcon={<LinkOffIcon sx={{ fontSize: '36px !important' }} />}
            onClick={unLink}
            disabled={!gatewayDetails?.organization_id}
            data-testid="gateway-details-btn-unlink"
          >
            {t('gateway_details.btn_unlink')}
          </Button>
        </Stack>
        <GatewayInformation gatewayId={id} gatewayDetails={gatewayDetails} />
        <GatewayCustomer gatewayDetails={gatewayDetails} />
        <GatewayMachines gatewayId={id} />
        <GatewayVersions gatewayDetails={gatewayDetails} />
        <GatewayNetwork gatewayDetails={gatewayDetails} />
        <GatewayLogs gatewayId={id} />
      </Stack>
      <UnLinkGateway handleModalParams={unlinkModalParam} handleClose={handleCloseUnlinkGateway} />
    </Container>
  );
}

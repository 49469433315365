import React from 'react';
import { Button, CircularProgress, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import T, { bool, func } from 'prop-types';
import { BATCH_STATUS_ENUM, BatchStatusIcon, FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultFieldValue,
  getFormikError,
  getFormikHelperText,
  isLoading,
  validateBlankSpace
} from 'helpers/utils';
import { StyledContent, StyledContentGrid, StyledGridItem, StyledHandlers, StyledNewDialog } from '../../elements';
import { IrisBatchStatusSchema } from '../../../../../attrs/formValidation';
import { IrisBatchStatusPropTypes } from '../../../../../helpers/propTypes';
import { getSelectedIrisBatchStatus } from '../../../../../redux/machines_v2/selectors';
import { updateIrisBatchStatus } from '../../../../../redux/machines_v2/actions';
import { StyledStatusSpan } from '../elements';

const labelAmend = 'machines.machine_details.batch_iris.status';

const IrisBatchRecordsEditStatus = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadingState } = useSelector(getSelectedIrisBatchStatus);

  function isSaving() {
    return isLoading(loadingState.status);
  }

  const submitForm = (values, formikHelpers) => {
    const convertedValues = {
      status_cancelled_value: Number(values.status_cancelled_value),
      status_completed_value: Number(values.status_completed_value),
      status_completed_before_target_value: Number(values.status_completed_before_target_value),
      status_completed_with_target_updates_value: Number(values.status_completed_with_target_updates_value),
      status_in_operation_value: Number(values.status_in_operation_value),
      status_loading_value: Number(values.status_loading_value),
      status_stopped_value: Number(values.status_stopped_value),
      status_variable: String(values.status_variable)
    };
    dispatch(updateIrisBatchStatus(machineId, convertedValues));
    handleClose(true);
    formikHelpers.resetForm();
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSaving()}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`default_actions.save`)}
        {isSaving() && <CircularProgress sx={{ marginLeft: 1, fontSize: '1.125rem' }} size={18} />}
      </Button>
    </StyledHandlers>
  );

  const handleChangeValue = handleChange => event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Formik
      validationSchema={IrisBatchStatusSchema}
      initialValues={{
        status_cancelled_value: getDefaultFieldValue(data.status_cancelled_value),
        status_completed_before_target_value: getDefaultFieldValue(data.status_completed_before_target_value),
        status_completed_value: getDefaultFieldValue(data.status_completed_value),
        status_completed_with_target_updates_value: getDefaultFieldValue(
          data.status_completed_with_target_updates_value
        ),
        status_in_operation_value: getDefaultFieldValue(data.status_in_operation_value),
        status_loading_value: getDefaultFieldValue(data.status_loading_value),
        status_stopped_value: getDefaultFieldValue(data.status_stopped_value),
        status_variable: getDefaultFieldValue(data.status_variable)
      }}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.modal_title`)}
            subTitle={t(`${labelAmend}.modal_subtitle`)}
            maxWidth="978px"
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem gridColumn="span 2">
                      <FormElements.TextField
                        error={getError('status_variable')}
                        helperText={getHelperText('status_variable')}
                        fullWidth
                        id="status_variable"
                        label={t(`${labelAmend}.status_variable`)}
                        margin="none"
                        size="small"
                        name="status_variable"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_variable)}
                        required
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.STOPPED)}
                        {t(`${labelAmend}.status_stopped`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_stopped_value')}
                        helperText={getHelperText('status_stopped_value')}
                        fullWidth
                        id="status_stopped_value"
                        label={t(`${labelAmend}.status_stopped_value`)}
                        margin="none"
                        size="small"
                        name="status_stopped_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_stopped_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.LOADING)}
                        {t(`${labelAmend}.status_loading`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_loading_value')}
                        helperText={getHelperText('status_loading_value')}
                        fullWidth
                        id="status_loading_value"
                        label={t(`${labelAmend}.status_loading_value`)}
                        margin="none"
                        size="small"
                        name="status_loading_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_loading_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.IN_OPERATION)}
                        {t(`${labelAmend}.status_in_operation`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_in_operation_value')}
                        helperText={getHelperText('status_in_operation_value')}
                        fullWidth
                        id="status_in_operation_value"
                        label={t(`${labelAmend}.status_in_operation_value`)}
                        margin="none"
                        size="small"
                        name="status_in_operation_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_in_operation_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED)}
                        {t(`${labelAmend}.status_completed`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_completed_value')}
                        helperText={getHelperText('status_completed_value')}
                        fullWidth
                        id="status_completed_value"
                        label={t(`${labelAmend}.status_completed_value`)}
                        margin="none"
                        size="small"
                        name="status_completed_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_completed_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED_BEFORE_TARGET)}
                        {t(`${labelAmend}.status_completed_before_target`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_completed_before_target_value')}
                        helperText={getHelperText('status_completed_before_target_value')}
                        fullWidth
                        id="status_completed_before_target_value"
                        label={t(`${labelAmend}.status_completed_before_target_value`)}
                        margin="none"
                        size="small"
                        name="status_completed_before_target_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_completed_before_target_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.COMPLETED_WITH_TARGET_UPDATES)}
                        {t(`${labelAmend}.status_completed_with_target_updates`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_completed_with_target_updates_value')}
                        helperText={getHelperText('status_completed_with_target_updates_value')}
                        fullWidth
                        id="status_completed_with_target_updates_value"
                        label={t(`${labelAmend}.status_completed_with_target_updates_value`)}
                        margin="none"
                        size="small"
                        name="status_completed_with_target_updates_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_completed_with_target_updates_value)}
                        type="number"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <StyledStatusSpan>
                        {BatchStatusIcon(BATCH_STATUS_ENUM.CANCELLED)}
                        {t(`${labelAmend}.status_cancelled`)}
                      </StyledStatusSpan>
                      <FormElements.TextField
                        error={getError('status_cancelled_value')}
                        helperText={getHelperText('status_cancelled_value')}
                        fullWidth
                        id="status_cancelled_value"
                        label={t(`${labelAmend}.status_cancelled_value`)}
                        margin="none"
                        size="small"
                        name="status_cancelled_value"
                        onBlur={handleBlur}
                        onChange={handleChangeValue(handleChange)}
                        value={getDefaultFieldValue(values.status_cancelled_value)}
                        type="number"
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

IrisBatchRecordsEditStatus.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: IrisBatchStatusPropTypes
};

IrisBatchRecordsEditStatus.defaultProps = {
  machineId: null,
  data: null
};

export default IrisBatchRecordsEditStatus;

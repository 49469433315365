import React, { useEffect } from 'react';
import T from 'prop-types';
import { FormElements, NotifyIcon } from 'web-components';
import { RadioGroup, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  getProductNotifyIconConstant,
  PRODUCT_NOTIFY,
  PRODUCT_PREVENTIVE,
  ROLE_NO_ACCESS
} from '../../../../attrs/products';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import { RadioLabel } from '../Licenses/elements';

const UserProductAccessTableRows = ({ productsAccess, patternValues, isOpen, setValuesChanged }) => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext();

  useEffect(() => {
    if (isOpen) {
      setValues(patternValues);
    }
  }, [isOpen]);

  useEffect(() => {
    if (values.notify_role !== patternValues.notify_role) {
      setValuesChanged(true);
    } else {
      setValuesChanged(false);
    }
  }, [values]);

  const handleRadioDisabled = (role, product) => {
    if (values.role_notify === ROLE_NO_ACCESS && product === PRODUCT_PREVENTIVE && role === ROLE_NO_ACCESS) {
      return true;
    }
    return values.role_preventive === ROLE_NO_ACCESS && product === PRODUCT_NOTIFY && role === ROLE_NO_ACCESS;
  };

  const handleRadioChange = ({ target: { name, value } }) => {
    setValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (Array.isArray(productsAccess)) {
    return productsAccess.map(productAccess => (
      <TableRow
        id={`edit_user_row_product_access_${productAccess.product}`}
        key={`edit_user_row_product_access_${productAccess.product}`}
      >
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
            <span style={{ display: 'flex', gap: '0.5rem' }}>
              <NotifyIcon
                fontSize="large"
                iconName={getProductNotifyIconConstant(productAccess.product)}
                style={{ color: '#2BACA4', fontSize: '24px' }}
              />
              {t(`products.${productAccess.product}`)}
            </span>
          </div>
        </TableCell>
        <TableCell>
          <RadioGroup
            row
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            name={`${productAccess.product}_role`}
            id={`${productAccess.product}_role`}
          >
            {Array.isArray(productAccess.roles) ? (
              productAccess.roles.map(role => (
                <RadioLabel key={role}>
                  <FormElements.RadioButton
                    margin="normal"
                    activeColor={COLOR_PRIMARY}
                    value={role}
                    id={`${productAccess.product}_role`}
                    name={`${productAccess.product}_role`}
                    onChange={handleRadioChange}
                    disabled={handleRadioDisabled(role, productAccess.product)}
                    checked={values[`${productAccess.product}_role`] === role}
                  />
                  <span>{t(`products.roles.${role}`)}</span>
                </RadioLabel>
              ))
            ) : (
              <RadioLabel key="no_access">
                <FormElements.RadioButton
                  margin="normal"
                  activeColor={COLOR_PRIMARY}
                  id={`${productAccess.product}_role`}
                  name={`${productAccess.product}_role`}
                  disabled
                />
                <span>{t(`products.roles.no_access`)}</span>
              </RadioLabel>
            )}
          </RadioGroup>
        </TableCell>
      </TableRow>
    ));
  }
  return <></>;
};

UserProductAccessTableRows.propTypes = {
  productsAccess: T.arrayOf(T.shape({})),
  patternValues: T.arrayOf(T.shape({})),
  isOpen: T.bool,
  setValuesChanged: T.func.isRequired
};

UserProductAccessTableRows.defaultProps = {
  productsAccess: [],
  patternValues: [],
  isOpen: false
};

export default UserProductAccessTableRows;

import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, Container } from '@mui/material';
import { Dialog } from 'web-components';
import { initialStaticValues } from '../../../../../attrs/sensorConfig';
import { StaticSensorDataSchema } from '../../../../../attrs/formValidation';
import { getFormikError, getFormikHelperText } from '../../../../../helpers/utils';
import {
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogSubHeader
} from '../../../../../attrs/style';
import { COLOR_PRIMARY } from '../../../../../attrs/colors';
import { StaticValuesFormFields } from './StaticValuesFormFields';

const StaticValuesDialogForm = ({ onSubmit, open, setOpen }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={initialStaticValues}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm({ values: '' });
      }}
      validationSchema={StaticSensorDataSchema}
      enableReinitialize
    >
      {({ dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
        const getError = getFormikError({ errors, touched });
        const getHelperText = getFormikHelperText({ errors, t });

        return (
          <Dialog open={open}>
            <>
              <StyledDialogHeader highlighColor={COLOR_PRIMARY} onClose={() => setOpen(false)}>
                {t('machines.form.sensor.modal.title')}
              </StyledDialogHeader>
              <StyledDialogSubHeader>{t('machines.form.sensor.modal.subtitle')}</StyledDialogSubHeader>
              <StyledDialogBody>
                <Container style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <StaticValuesFormFields
                    getError={getError}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    getHelperText={getHelperText}
                    values={values}
                  />
                </Container>
              </StyledDialogBody>
              <StyledDialogActions>
                <Button variant="text" color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  data-testid="machine-details--add-static-sensor-button"
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                  onClick={handleSubmit}
                  variant="contained"
                  type="button"
                >
                  {t('form.add')}
                </Button>
              </StyledDialogActions>
            </>
          </Dialog>
        );
      }}
    </Formik>
  );
};

StaticValuesDialogForm.propTypes = {
  onSubmit: T.func.isRequired,
  open: T.bool.isRequired,
  setOpen: T.func.isRequired
};

export { StaticValuesDialogForm };

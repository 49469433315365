import React from 'react';
import { BuildCircleOutlined, CancelOutlined, ErrorOutlineOutlined } from '@mui/icons-material';
import { blue, orange } from '@mui/material/colors';
import { FormElements, NotifyTypo } from 'web-components';
import { validateBlankSpace } from '../../../../../../../helpers/utils';
import { Box } from '@mui/material';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledContentGrid } from '../../elements';

const statusTypeToIconName = statusType =>
  ({
    critical: <CancelOutlined color="error" sx={{ fontSize: 25 }} />,
    warning: <ErrorOutlineOutlined sx={{ color: orange[500], fontSize: 25 }} />,
    maintenance: <BuildCircleOutlined sx={{ color: blue[500], fontSize: 25 }} />
  }[statusType]);

const StatusCard = ({ getError, getHelperText, handleBlur, handleChange, statusType, values }) => {
  const { t } = useTranslation();
  const valueFieldName = `${statusType.type}.value`;

  const handleChangeAddress = event => {
    const { name, value } = event.target;
    handleChange({ target: { name, value: validateBlankSpace(value) } });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {statusTypeToIconName(statusType.type)}
        <NotifyTypo.Body1 fontWeight="bold" sx={{ ml: 1 }}>
          {t(`machines.status.${statusType.type}`)}
        </NotifyTypo.Body1>
      </Box>
      <StyledContentGrid>
        <FormElements.TextField
          fullWidth
          error={getError(valueFieldName)}
          helperText={getHelperText(valueFieldName)}
          id={valueFieldName}
          label={t('machines.machine_details.messages.info_table.class_name')}
          margin="normal"
          name={valueFieldName}
          onBlur={handleBlur}
          onChange={handleChangeAddress}
          required={statusType.required}
          value={values[statusType.type].value}
        />
      </StyledContentGrid>
    </Box>
  );
};

StatusCard.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  values: T.shape({
    alarm: T.shape({}),
    warning: T.shape({}),
    maintenance: T.shape({})
  }).isRequired,
  statusType: T.shape({
    type: T.string,
    required: T.bool
  }).isRequired
};

export default StatusCard;

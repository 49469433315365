import React from 'react';
import { Link } from 'react-router-dom';
import T from 'prop-types';
import { Avatar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotifyTypo, useResolutionCheck } from 'web-components';

import NoUsersImg from '../../attrs/images/user_management_no_users.svg';
import NoMachinesImg from '../../attrs/images/dashboard_no_machines.svg';
import NoGatewaysImg from '../../attrs/images/gateways_empaty.svg';
import NoSensorsImg from '../../attrs/images/dashboard_no_sensors.svg';
import NoSensorsDataImg from '../../attrs/images/service_no_sensor_data.svg';
import NoErrorCodeImg from '../../attrs/images/dashboard_no_error_code.svg';
import NoStaticSensor from '../../attrs/images/dashboard_no_static_sensors.svg';
import NoLicenses from '../../attrs/images/customers_no_licenses.svg';

import { EmptyStateWrapper, TextEmptyState } from './elements';
import { EMPTY_STATE_IMAGE_SIZE_DESKTOP, EMPTY_STATE_IMAGE_SIZE_TABLET } from '../../attrs/layout';

const { Heading1, Heading2, PageHeadingSubtitle } = NotifyTypo;

const EMPTY_STATES_IMG = {
  customers: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_customers_active',
    actionText: 'empty_state.no_customers_active_action_text'
  },
  customer: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_customer_found'
  },
  user_management_pending: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_pending',
    actionText: 'empty_state.no_users_pending_action_text',
    small: true
  },
  user_management_active: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_users_active',
    actionText: 'empty_state.no_users_active_action_text',
    small: true
  },
  machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines',
    actionText: 'empty_state.no_machines_action_text',
    actionButtonText: 'empty_state.no_machines_button_text',
    actionLink: '/machines'
  },
  machines_created: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machines_created'
  },
  machine_details_sensor: {
    imgSrc: NoSensorsImg,
    mainText: 'empty_state.no_machine_details_sensor',
    small: true,
    actionButton: true
  },
  machine_details_error_code: {
    imgSrc: NoErrorCodeImg,
    mainText: 'empty_state.no_machine_details_error_code',
    small: true,
    actionButton: true
  },
  machine_details_status: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_machine_details_static_sensor',
    small: true
  },
  machine_details_static_sensor: {
    imgSrc: NoStaticSensor,
    mainText: 'empty_state.no_machine_details_static_sensor',
    small: true
  },
  machine_customer_detail: {
    imgSrc: NoStaticSensor,
    mainText: 'empty_state.no_machine_customer_detail',
    actionText: 'empty_state.no_machine_customer_detail_subtitle',
    small: true
  },
  // TODO: Wave said she wants to provide a gatway image later
  gateways: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateways'
  },
  gateways_not_configured: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateways_not_configured'
  },
  gateways_configured: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateways_configured'
  },
  gateway: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateway_found'
  },
  gateway_machines: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateway_machines_assigned',
    small: true
  },
  gateway_customer_detail: {
    imgSrc: NoGatewaysImg,
    mainText: 'empty_state.no_gateway_customer_detail_title',
    actionText: 'empty_state.no_gateway_customer_detail_subtitle',
    small: true
  },
  licenses: {
    imgSrc: NoLicenses,
    mainText: 'empty_state.no_licenses_added',
    actionText: 'empty_state.no_licenses_added_subtitle',
    small: true
  },
  sensor_data: {
    img: NoSensorsDataImg,
    mainText: 'empty_state.no_sensor_data_title',
    actionText: 'empty_state.no_sensor_data_subtitle'
  },
  error_code: {
    img: NoErrorCodeImg,
    mainText: 'empty_state.no_error_code_title',
    actionText: 'empty_state.no_error_code_subtitle',
    small: true
  },
  no_license: {
    imgSrc: NoUsersImg,
    mainText: 'empty_state.no_license_title',
    actionText: 'empty_state.no_license_subtitle',
    small: true
  },
  gtw_assignable_machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gtw_machines_to_assignable'
  },
  gtw_link_machines: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gtw_machines_linked',
    actionText: 'empty_state.no_gtw_machines_linked_action_text',
    small: true
  },
  gtw_versions: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gtw_versions',
    actionText: 'empty_state.no_gtw_versions_action_text',
    small: true
  },
  gtw_network: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gtw_network',
    actionText: 'empty_state.no_gtw_network_action_text',
    small: true
  },
  gtw_logs: {
    imgSrc: NoMachinesImg,
    mainText: 'empty_state.no_gtw_logs',
    actionText: 'empty_state.no_gtw_logs_action_text',
    small: true
  }
};

export const EmptyState = ({ type, action, actionButton, top }) => {
  const { t } = useTranslation();
  const { isTablet } = useResolutionCheck();
  const typeState = EMPTY_STATES_IMG[type];

  let imgSize = isTablet ? EMPTY_STATE_IMAGE_SIZE_TABLET : EMPTY_STATE_IMAGE_SIZE_DESKTOP;
  let HeadingComponent = Heading1;

  if (typeState.small) {
    imgSize /= 3;
    HeadingComponent = Heading2;
  }

  return (
    <EmptyStateWrapper top={top} data-testid="empty-state">
      <Avatar style={{ height: imgSize, width: imgSize }} src={typeState.imgSrc} />
      <TextEmptyState>
        {React.createElement(
          HeadingComponent,
          {
            gutterBottom: true,
            align: 'left',
            style: {
              margin: '0',
              fontSize: '20px'
            }
          },
          [t(typeState.mainText)]
        )}
        {!action && typeState.actionText && (
          <PageHeadingSubtitle align="left" sx={{ color: '#7A7D85', fontSize: '14px', marginTop: 1 }}>
            {t(typeState.actionText)}
          </PageHeadingSubtitle>
        )}
        {typeState.actionLink && (
          <Button
            component={Link}
            color="primary"
            variant="contained"
            to={t(typeState.actionLink)}
            style={{ marginTop: '1rem' }}
          >
            {t(typeState.actionButtonText)}
          </Button>
        )}
        {typeState.actionButton && actionButton}
      </TextEmptyState>
    </EmptyStateWrapper>
  );
};

EmptyState.propTypes = {
  type: T.string.isRequired,
  actionButton: T.node,
  action: T.shape({ permissionBase: T.string, permissionField: T.string }),
  top: T.number
};

EmptyState.defaultProps = {
  actionButton: null,
  action: null,
  top: 30
};

export default EmptyState;

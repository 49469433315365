import React from 'react';
import { Button, Divider, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import T, { bool, func } from 'prop-types';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationAddressInPLC } from 'attrs/formValidation';
import {
  getChangeNumberHandler,
  getFormikError,
  getFormikHelperText,
  isLoading,
  numberOrEmpty,
  validateBlankSpace
} from 'helpers/utils';
import {
  StyledContent,
  StyledContentGrid,
  StyledContentGridColumn,
  StyledGridItem,
  StyledHandlers,
  StyledNewDialog
} from '../../elements';
import { updatePlcMachine, updatePlcMachineIris } from '../../../../../redux/machines/actions';
import { PlcProtocols, PlcRacks, PlcSlots, PlcType } from '../../../../../attrs/plcConfigValues';
import { getSelectedPlcMachine } from '../../../../../redux/machines/selectors';

const labelAmend = 'machines.machine_details';

const EditPlc = ({ open, machineId, handleClose, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loadingState } = useSelector(getSelectedPlcMachine);

  const isSubmitting = isLoading(loadingState.status);

  const errorToastProps = {
    variant: 'error',
    autoHideDuration: 3000
  };

  const submitForm = (values, formikHelpers) => {
    let payload;

    if (values.type === PlcType.IRIS_V3) {
      payload = {
        database_name: values.database_name,
        variables_table_name: values.variables_table_name,
        type: PlcType.IRIS_V3
      };
      try {
        dispatch(updatePlcMachineIris(machineId, payload));
        handleClose(true);
        formikHelpers.resetForm();
      } catch (error) {
        if (error.response && error.response.status === 500) {
          enqueueSnackbar(t('machines.generic_error'), errorToastProps);
        }
      }
    } else {
      payload = {
        machine_id: machineId,
        plc_info: {
          address: values.address,
          port: values.type === PlcType.SIEMENS_S7 ? values.port : null,
          rack: values.type === PlcType.SIEMENS_S7 ? values.rack : null,
          slot: values.slot,
          type: values.type
        }
      };
      try {
        dispatch(updatePlcMachine(machineId, payload));
        handleClose(true);
        formikHelpers.resetForm();
      } catch (error) {
        if (error.response && error.response.status === 500) {
          enqueueSnackbar(t('machines.generic_error'), errorToastProps);
        }
      }
    }
  };

  const actionBar = (handleSubmit, errors, resetForm, dirty) => (
    <StyledHandlers>
      <Button
        color="secondary"
        onClick={() => {
          resetForm();
          handleClose();
        }}
      >
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        data-testid="plc-modal-submit"
        style={{ padding: '1rem 2.5rem' }}
        disabled={!dirty || Object.keys(errors).length !== 0 || isSubmitting}
        onClick={() => handleSubmit()}
        onMouseDown={event => {
          event.preventDefault();
        }}
      >
        {t(`${labelAmend}.handle_button`)}
      </Button>
    </StyledHandlers>
  );

  return (
    <Formik
      initialValues={{
        type: data.type || '',
        address: data.address || '',
        port: data.port,
        rack: data.rack,
        slot: data.slot,
        database_name: data.database_name,
        variables_table_name: data.variables_table_name
      }}
      validationSchema={ValidationAddressInPLC}
      onSubmit={(values, formikHelpers) => submitForm(values, formikHelpers)}
      enableReinitialize
    >
      {({ setFieldValue, errors, handleBlur, handleChange, touched, values, handleSubmit, resetForm, dirty }) => {
        const getHelperText = getFormikHelperText({ errors, t });
        const getError = getFormikError({ errors, touched });

        return (
          <StyledNewDialog
            open={open}
            handleClose={(event, reason) => {
              if (reason && reason === 'backdropClick') return;
              resetForm();
              handleClose();
            }}
            title={t(`${labelAmend}.plc_modal.plc_title`)}
            subTitle={t(`${labelAmend}.plc_modal.plc_subtitle`)}
            displayActionBar="true"
            actionBar={actionBar(handleSubmit, errors, resetForm, dirty)}
            maxWidth="864px"
            content={
              <StyledContent>
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGridColumn>
                    <StyledGridItem>
                      <FormElements.Select
                        helperText={getHelperText('type')}
                        error={getError('type')}
                        value={values.type}
                        fullWidth
                        label={t(`${labelAmend}.plc_protocol`)}
                        handleChange={handleChange}
                        name="type"
                        id="type"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required
                        withEmptyField
                      >
                        {PlcProtocols.map(protocol => (
                          <option key={protocol.value} value={protocol.value}>
                            {protocol.type}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                  </StyledContentGridColumn>
                </FormControl>
                <Divider />
                <FormControl sx={{ width: '100%' }}>
                  <StyledContentGrid>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.type === PlcType.IRIS_V3}
                        helperText={getHelperText('address')}
                        error={getError('address')}
                        value={values.address}
                        fullWidth
                        label={t(`${labelAmend}.plc_ip`)}
                        onChange={event => {
                          const newValue = validateBlankSpace(event.target.value);
                          setFieldValue('address', newValue);
                        }}
                        name="address"
                        id="address"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required={values.type !== PlcType.IRIS_V3}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.type !== PlcType.SIEMENS_S7}
                        error={getError('port')}
                        helperText={getHelperText('port')}
                        fullWidth
                        id="port"
                        label={t(`${labelAmend}.plc_port`)}
                        name="port"
                        onBlur={handleBlur}
                        onChange={getChangeNumberHandler(setFieldValue)('port')}
                        type="number"
                        required={values.type === PlcType.SIEMENS_S7}
                        value={numberOrEmpty(values.port)}
                        size="small"
                        margin="none"
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.Select
                        disabled={values.type !== PlcType.SIEMENS_S7}
                        error={getError('rack')}
                        helperText={getHelperText('rack')}
                        fullWidth
                        handleChange={getChangeNumberHandler(setFieldValue)('rack')}
                        id="rack"
                        label={t(`${labelAmend}.plc_rack`)}
                        name="rack"
                        onBlur={handleBlur}
                        required={values.type === PlcType.SIEMENS_S7}
                        value={values.rack}
                        size="small"
                        margin="none"
                        withEmptyField
                      >
                        {PlcRacks.map(rack => (
                          <option key={rack.value} value={rack.value}>
                            {rack.value}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.Select
                        disabled={values.type === PlcType.IRIS_V3}
                        error={getError('slot')}
                        helperText={getHelperText('slot')}
                        fullWidth
                        handleChange={getChangeNumberHandler(setFieldValue)('slot')}
                        id="slot"
                        label={t(`${labelAmend}.plc_slot`)}
                        name="slot"
                        onBlur={handleBlur}
                        required={values.type !== PlcType.IRIS_V3}
                        value={values.slot}
                        size="small"
                        margin="none"
                        withEmptyField
                      >
                        {PlcSlots.map(slot => (
                          <option key={slot.value} value={slot.value}>
                            {slot.value}
                          </option>
                        ))}
                      </FormElements.Select>
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.type !== PlcType.IRIS_V3}
                        helperText={getHelperText('database_name')}
                        error={getError('database_name')}
                        value={values.database_name}
                        fullWidth
                        label={t(`${labelAmend}.plc_database_name`)}
                        onChange={event => {
                          const newValue = validateBlankSpace(event.target.value);
                          setFieldValue('database_name', newValue);
                        }}
                        name="database_name"
                        id="database_name"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required={values.type === PlcType.IRIS_V3}
                      />
                    </StyledGridItem>
                    <StyledGridItem>
                      <FormElements.TextField
                        disabled={values.type !== PlcType.IRIS_V3}
                        helperText={getHelperText('variables_table_name')}
                        error={getError('variables_table_name')}
                        value={values.variables_table_name}
                        fullWidth
                        label={t(`${labelAmend}.plc_variables_table_name`)}
                        onChange={event => {
                          const newValue = validateBlankSpace(event.target.value);
                          setFieldValue('variables_table_name', newValue);
                        }}
                        name="variables_table_name"
                        id="variables_table_name"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        required={values.type === PlcType.IRIS_V3}
                      />
                    </StyledGridItem>
                  </StyledContentGrid>
                </FormControl>
              </StyledContent>
            }
          />
        );
      }}
    </Formik>
  );
};

EditPlc.propTypes = {
  machineId: T.string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  data: T.shape({
    address: T.string,
    port: T.number,
    rack: T.number,
    slot: T.number,
    type: T.string,
    database_name: T.string,
    variables_table_name: T.string
  })
};

EditPlc.defaultProps = {
  machineId: null,
  data: null
};

export default EditPlc;

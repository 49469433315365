import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button, CircularProgress, Fab } from '@mui/material';
import { Dialog, NotifyIcon, ResponsiveIconButton, Tabs, useResolutionCheck } from 'web-components';
import { useTheme } from '@mui/material/styles';
import FormAlert from '../FormAlert';
import { getMachines } from '../../../redux/rootSelectors';
import { setConfirmationRequest } from '../../../redux/ui/confirmations/actions';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import {
  getChangeDataTypeHandler,
  getChangeFloatHandler,
  getChangeNumberHandler,
  getChangeSensorTypeHandler,
  getFormikError,
  getFormikHelperText,
  isError,
  isLoading,
  isSuccess
} from '../../../helpers/utils';
import { createMachine, resetMachineState } from '../../../redux/machines/actions';
import { createTabDefinitions, tabCreator } from './MachineDetail/tabs';
import { StyledTabNavigation } from './elements';
import { MachineSchema } from '../../../attrs/formValidation';
import initialMachineValues from './initialMachineValues';
import {
  ContainerMachine,
  SidebarCollapseItem,
  SidebarCollapseItemIcon,
  TabsWrapper,
  TabsWrapperCollapse,
  Wrapper
} from '../../../containers/Machines/MachineDetail/MachineDetail.styled';

const MachineCreate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile, isSmallDevice } = useResolutionCheck();
  const { createLoadingState } = useSelector(getMachines);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [showBatch, setShowBatch] = useState(initialMachineValues.enabled_config.batch);
  const [collapseMenu, setCollapseMenu] = useState(false);

  let tabDefinitions = createTabDefinitions(showBatch);
  const selectedTab = tabDefinitions[selected];

  const isSubmitting = isLoading(createLoadingState.status);

  const handleFormSubmit = values => {
    dispatch(createMachine(values));
  };

  const handleFeatureChange = (name, value) => {
    if (name === 'enabled_config.batch') {
      setShowBatch(value);
      tabDefinitions = createTabDefinitions(value);
    }
  };

  const handleCloseDialog = dirty => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          message: 'dialog.confirmation.form_unsaved_changes',
          action: () => {
            setOpen(false);
            setSelected(0);
            dispatch(resetMachineState());
          }
        })
      );
    } else {
      setOpen(false);
      setSelected(0);
      dispatch(resetMachineState());
    }
  };

  // Close Dialog on submit success
  useEffect(() => {
    if (isSuccess(createLoadingState.status)) {
      setOpen(false);
      setSelected(0);
      dispatch(resetMachineState());
    }
  }, [createLoadingState, dispatch]);

  return (
    <>
      {isMobile ? (
        <Fab
          aria-label="add"
          color="primary"
          onClick={() => setOpen(true)}
          style={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
        >
          <NotifyIcon iconName="plus" />
        </Fab>
      ) : (
        <ResponsiveIconButton
          data-testid="machines-create-new-button"
          buttonText={t('machines.create_machine_button_text')}
          color="primary"
          iconName="plus"
          onClick={() => setOpen(true)}
          variant="contained"
        />
      )}
      <Dialog open={open} fullScreen>
        <Formik
          initialValues={initialMachineValues}
          onSubmit={values => handleFormSubmit(values)}
          validationSchema={MachineSchema}
          enableReinitialize
        >
          {({ values, dirty, handleSubmit, handleBlur, handleChange, setFieldValue, touched, errors }) => {
            const isSubmitDisabled = !dirty || Object.keys(errors).length !== 0;
            return (
              <>
                <Dialog.Header highlightColor={COLOR_PRIMARY} onClose={() => handleCloseDialog(dirty)}>
                  {t('machines.form.create_machine_title')}
                </Dialog.Header>
                <Dialog.SubHeader>{t('machines.form.create_machine_subtitle')}</Dialog.SubHeader>

                <Dialog.Body>
                  <ContainerMachine>
                    {collapseMenu ? (
                      <Wrapper type="sidebar-collapse" style={{ maxWidth: '50px' }} theme={theme}>
                        <TabsWrapperCollapse>
                          <SidebarCollapseItem onClick={() => setCollapseMenu(false)}>
                            <SidebarCollapseItemIcon iconName="doubleChevronRight" fontSize="large" />
                          </SidebarCollapseItem>
                          <Tabs
                            selected={selected}
                            orientation={isSmallDevice ? 'horizontal' : 'vertical'}
                            onChange={setSelected}
                            ariaLabel="machineTabs"
                            tabDefinitions={tabDefinitions}
                            tabCreator={tabCreator(isMobile, t)}
                            maxRowItemCount={isSmallDevice ? 5 : Number.MAX_SAFE_INTEGER}
                          />
                        </TabsWrapperCollapse>
                      </Wrapper>
                    ) : (
                      <Wrapper type="sidebar" theme={theme}>
                        <TabsWrapper>
                          <SidebarCollapseItem onClick={() => setCollapseMenu(true)}>
                            <SidebarCollapseItemIcon iconName="doubleChevronLeft" fontSize="large" />
                          </SidebarCollapseItem>
                          <Tabs
                            selected={selected}
                            orientation={isSmallDevice ? 'horizontal' : 'vertical'}
                            onChange={setSelected}
                            ariaLabel="machineTabs"
                            tabDefinitions={tabDefinitions}
                            tabCreator={tabCreator(isMobile, t)}
                            maxRowItemCount={isSmallDevice ? 5 : Number.MAX_SAFE_INTEGER}
                          />
                        </TabsWrapper>
                      </Wrapper>
                    )}
                    <Wrapper type="content-wrapper" theme={theme} style={{ width: '100%' }}>
                      {React.createElement(selectedTab.content, {
                        errors,
                        getError: getFormikError({ errors, touched }),
                        getHelperText: getFormikHelperText({ errors, t }),
                        handleBlur,
                        handleChange,
                        handleChangeNumber: getChangeNumberHandler(setFieldValue),
                        handleChangeFloat: getChangeFloatHandler(setFieldValue),
                        handleChangeSensorType: getChangeSensorTypeHandler(setFieldValue),
                        handleChangeEngineeringDataType: getChangeDataTypeHandler(setFieldValue),
                        handleFeatureChange,
                        setFieldValue,
                        touched,
                        values,
                        isSubmitting,
                        dirty,
                        handleSubmit,
                        submitDisabled: isSubmitDisabled
                      })}
                      <StyledTabNavigation>
                        {selected > 0 ? (
                          <Button
                            data-testid="machines-create-new--previoius-tab"
                            type="button"
                            onClick={() => setSelected(selected - 1)}
                            startIcon={<NotifyIcon iconName="chevronLeft" />}
                          >
                            {t('machines.form.pagination.previous')}
                          </Button>
                        ) : (
                          <div />
                        )}
                        {selected < tabDefinitions.filter(tab => !tab.disabled).length - 1 && (
                          <Button
                            data-testid="machines-create-new--next-tab"
                            type="button"
                            onClick={() => setSelected(selected + 1)}
                            endIcon={<NotifyIcon iconName="chevronRight" />}
                          >
                            {t('machines.form.pagination.next')}
                          </Button>
                        )}
                      </StyledTabNavigation>
                      {isError(createLoadingState.status) && (
                        <FormAlert message={t('errors.machines.machine_create')} />
                      )}
                    </Wrapper>
                  </ContainerMachine>
                </Dialog.Body>
                <Dialog.Actions>
                  <Button
                    color="primary"
                    data-testid="machine-details-submit"
                    disabled={Object.keys(errors).length !== 0 || !dirty || isSubmitting}
                    endIcon={
                      isSubmitting && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
                    }
                    onClick={handleSubmit}
                    variant="contained"
                    type="submit"
                  >
                    {t('machines.form.create_machine_button')}
                  </Button>
                </Dialog.Actions>
              </>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default MachineCreate;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { CardContent, Container, IconButton, Paper, TableContainer } from '@mui/material';
import { DataTable, NotifyIcon, ResponsiveIconButton } from 'web-components';
import { FieldArray } from 'formik';
import { v4 as uuid } from 'uuid';
import { StyledCard, StyledContentHeaderColumn, StyledContentWrapper } from '../elements';
import {
  MachineBreadcrumb,
  MachinePageHeader,
  MachinePageHeaderWithAddButton
} from '../../../../../containers/Machines/MachineDetail/MachineDetail.styled';
import { PageHeaderSubTitle, PageHeaderTitle } from '../../../../../elements';
import EmptyState from '../../../../EmptyState';
import { StaticValuesDialogForm } from './StaticValuesDialogForm';
import { StaticValuesDialogEdit } from './StaticValuesDialogEdit';
import { metricDataType } from '../../../../../attrs/sensorConfig';
import Footer from '../../../../DataTable/Footer';
import { StaticValuesDialogDelete } from './StaticValuesDialogDelete';

const StaticValues = ({
  getError,
  getHelperText,
  handleBlur,
  handleChange,
  handleChangeSensorType,
  handleChangeEngineeringDataType,
  setFieldValue,
  values,
  setValues
}) => {
  const { t } = useTranslation();

  const [shouldShowNewSensorDialog, toggleNewSensorDialog] = useState(false);
  const [shouldShowEditSensorDialog, toggleEditSensorDialog] = useState(false);
  const [shouldShowDeleteSensorDialog, toggleDeleteSensorDialog] = useState(false);
  const [infoStaticSensor, setInfoStaticSensor] = useState({});
  const [staticValuesData, setStaticValuesData] = useState(
    values.configuration.plc.metrics.filter(metric => metric.is_static === true)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    colId: 'name',
    order: DataTable.ORDER_ASC
  });
  const [currentView, setCurrentView] = useState([]);

  const hasNoCodes =
    ((((values || {}).configuration || {}).plc || {}).metrics.filter(row => row.is_static === true) || []).length === 0;
  const shouldShowEmptyState = hasNoCodes && !shouldShowNewSensorDialog;

  const countStaticValues = staticValuesData.length;

  const rowsPerPage = 10;

  useEffect(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;

    setCurrentView(
      DataTable.sortFn(
        staticValuesData.slice(startIndex, startIndex + rowsPerPage),
        currentSort.colId,
        currentSort.order
      )
    );
  }, [staticValuesData, currentPage, currentSort.colId, currentSort.order]);

  const handleSort = (colId, order) => {
    setCurrentSort({ colId, order });
    setCurrentView(DataTable.sortFn(currentView, colId, order));
  };

  const breadcrumbObj = [
    { route: '/machines', name: `${t('machines.title')}` },
    { route: `/machines/${values.id || ''}`, name: values.commission_number || t('machines.detail.new_machine') },
    { route: null, name: `${t('machines.detail.static_values.title')}` }
  ];

  const handleEdit = row => {
    setInfoStaticSensor(row);
    toggleEditSensorDialog(true);
  };

  const handleDelete = row => {
    setInfoStaticSensor(row);
    toggleDeleteSensorDialog(true);
  };

  const handleAddNewSensor = (arrayHelpers, newSensorValues) => {
    arrayHelpers.push({ ...newSensorValues, id_tmp: uuid() });
    setStaticValuesData([...staticValuesData, { ...newSensorValues }]);
    toggleNewSensorDialog(false);
  };

  const handleEditSensor = (arrayHelpers, editedSensorValues) => {
    const index = values.configuration.plc.metrics.findIndex(row => {
      if (row.static_sensor_id && editedSensorValues.static_sensor_id) {
        return row.static_sensor_id === editedSensorValues.static_sensor_id;
      }
      if (row.id_tmp && editedSensorValues.id_tmp) {
        return row.id_tmp === editedSensorValues.id_tmp;
      }
      return false;
    });

    // values.configuration.plc.metrics[index] = editedSensorValues;
    arrayHelpers.remove(index);
    arrayHelpers.push({ ...editedSensorValues });

    const indexLocal = staticValuesData.findIndex(row => {
      if (row.static_sensor_id && editedSensorValues.static_sensor_id) {
        return row.static_sensor_id === editedSensorValues.static_sensor_id;
      }
      if (row.id_tmp && editedSensorValues.id_tmp) {
        return row.id_tmp === editedSensorValues.id_tmp;
      }
      return false;
    });

    staticValuesData.splice(indexLocal, 1);
    setStaticValuesData([...staticValuesData, { ...editedSensorValues }]);

    toggleEditSensorDialog(false);
  };

  const handleDeleteSensor = (arrayHelpers, selectedSensor) => {
    const index = values.configuration.plc.metrics.findIndex(row => {
      if (row.static_sensor_id && selectedSensor.static_sensor_id) {
        return row.static_sensor_id === selectedSensor.static_sensor_id;
      }
      if (row.id_tmp && selectedSensor.id_tmp) {
        return row.id_tmp === selectedSensor.id_tmp;
      }
      return false;
    });

    arrayHelpers.remove(index);

    const indexLocal = staticValuesData.findIndex(row => {
      if (row.static_sensor_id && selectedSensor.static_sensor_id) {
        return row.static_sensor_id === selectedSensor.static_sensor_id;
      }
      if (row.id_tmp && selectedSensor.id_tmp) {
        return row.id_tmp === selectedSensor.id_tmp;
      }
      return false;
    });

    staticValuesData.splice(indexLocal, 1);

    setStaticValuesData([...staticValuesData]);

    toggleDeleteSensorDialog(false);

    // TODO: show the snackbar with the success
  };

  const generateColumns = (handleEditRow, handleDeleteRow) => [
    { id: 'name', label: t('machines.form.sensor.short_name'), sortable: false, style: { width: '50%' } },
    { id: 'custom_unit', label: t('machines.form.sensor.custom_unit'), sortable: false, style: { width: '20%' } },
    { id: 'custom_period', label: t('machines.form.sensor.period'), sortable: false, style: { width: '20%' } },
    {
      id: 'edit',
      label: '',
      sortable: false,
      cellRenderer: (row, index) => (
        <IconButton
          aria-label="edit_role"
          color="primary"
          onClick={e => {
            e.stopPropagation();
            handleEditRow(row, index);
          }}
        >
          <NotifyIcon fontSize="small" iconName="edit" />
        </IconButton>
      )
    },
    {
      id: 'delete',
      label: '',
      sortable: false,
      cellRenderer: row => (
        <IconButton
          aria-label="delete_role"
          style={{ color: '#F13309' }}
          onClick={e => {
            e.stopPropagation();
            handleDeleteRow(row);
          }}
        >
          <NotifyIcon fontSize="small" iconName="delete" />
        </IconButton>
      )
    }
  ];

  const handleChangePage = page => setCurrentPage(page);

  const props = {
    columns: generateColumns(handleEdit, handleDelete),
    rows: currentView,
    footer: (
      <Footer
        currentPage={currentPage}
        totalPages={Math.ceil(countStaticValues / rowsPerPage) || 1}
        totalLength={rowsPerPage || 0}
        totalItemsPerPage={rowsPerPage || 0}
        onChange={handleChangePage}
      />
    ),
    defaultSort: currentSort,
    sortFn: handleSort,
    onEdit: handleEdit
  };

  return (
    <>
      {shouldShowEmptyState ? (
        <StyledContentWrapper elevation={3}>
          <StyledContentHeaderColumn>
            <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

            <MachinePageHeaderWithAddButton>
              <MachinePageHeader>
                <PageHeaderTitle>{t('machines.detail.static_values.title')}</PageHeaderTitle>
                <PageHeaderSubTitle>{t('machines.detail.static_values.subtitle')}</PageHeaderSubTitle>
              </MachinePageHeader>

              <ResponsiveIconButton
                data-testid="machine-details--new-static-sensor-button"
                buttonText={t('machines.form.static_values.add_new_static_value')}
                color="primary"
                iconName="plus"
                variant="outlined"
                style={{ height: '56px' }}
                onClick={() => {
                  toggleNewSensorDialog(true);
                }}
              />
            </MachinePageHeaderWithAddButton>
          </StyledContentHeaderColumn>

          <StyledCard>
            <CardContent>
              <EmptyState type="machine_details_static_sensor" />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '12px', color: '#7A7D85' }}>
                  {t('empty_state.no_machine_details_static_sensor_subtitle')}
                </span>
              </div>
            </CardContent>
          </StyledCard>
        </StyledContentWrapper>
      ) : (
        <FieldArray name="configuration.plc.metrics">
          {arrayHelpers => (
            <>
              <StyledContentWrapper elevation={3}>
                <StyledContentHeaderColumn>
                  <MachineBreadcrumb aria-label="breadcrumb" data={breadcrumbObj} />

                  <MachinePageHeaderWithAddButton>
                    <MachinePageHeader>
                      <PageHeaderTitle>{t('machines.detail.static_values.title')}</PageHeaderTitle>
                      <PageHeaderSubTitle>{t('machines.detail.static_values.subtitle')}</PageHeaderSubTitle>
                    </MachinePageHeader>

                    <ResponsiveIconButton
                      data-testid="machine-details--new-static-sensor-button"
                      buttonText={t('machines.form.static_values.add_new_static_value')}
                      color="primary"
                      iconName="plus"
                      variant="outlined"
                      style={{ height: '56px' }}
                      onClick={() => toggleNewSensorDialog(true)}
                    />
                  </MachinePageHeaderWithAddButton>
                </StyledContentHeaderColumn>

                <Container style={{ marginTop: '2rem' }}>
                  <StaticValuesDialogForm
                    setOpen={toggleNewSensorDialog}
                    onSubmit={newSensorValues => handleAddNewSensor(arrayHelpers, newSensorValues)}
                    open={shouldShowNewSensorDialog}
                  />

                  <TableContainer component={Paper}>
                    <DataTable hover {...props} />
                    <StaticValuesDialogEdit
                      setOpen={toggleEditSensorDialog}
                      onSubmit={editSensorValues => handleEditSensor(arrayHelpers, editSensorValues)}
                      open={shouldShowEditSensorDialog}
                      sensor={infoStaticSensor}
                      getError={getError}
                      getHelperText={getHelperText}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleChangeSensorType={handleChangeSensorType}
                      handleChangeEngineeringDataType={handleChangeEngineeringDataType}
                      setFieldValue={setFieldValue}
                      values={values}
                      setValues={setValues}
                      namespace="static-values-dialog-edit"
                    />
                    <StaticValuesDialogDelete
                      onSubmit={row => handleDeleteSensor(arrayHelpers, row)}
                      setOpen={toggleDeleteSensorDialog}
                      open={shouldShowDeleteSensorDialog}
                      sensor={infoStaticSensor}
                    />
                  </TableContainer>
                </Container>
              </StyledContentWrapper>
            </>
          )}
        </FieldArray>
      )}
    </>
  );
};

StaticValues.propTypes = {
  getError: T.func.isRequired,
  getHelperText: T.func.isRequired,
  handleBlur: T.func.isRequired,
  handleChange: T.func.isRequired,
  handleChangeSensorType: T.func.isRequired,
  handleChangeEngineeringDataType: T.func.isRequired,
  setFieldValue: T.func.isRequired,
  values: T.shape({
    id: T.string,
    commission_number: T.string,
    configuration: T.shape({
      plc: T.shape({
        metrics: T.arrayOf(metricDataType.isRequired).isRequired
      })
    })
  }).isRequired,
  setValues: T.func
};

StaticValues.defaultProps = {
  setValues: () => null
};

export { StaticValues };

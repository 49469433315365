import React from 'react';
import T from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { StyledNewDialog, StyledHandlers } from './elements';
import { GeneralInformationForm } from './FormElements/GeneralInformationForm';

const CustomerForm = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, dirty, errors } = useFormikContext();

  const submitDisabled = !dirty || Object.keys(errors).length !== 0;

  const actionBar = (
    <StyledHandlers>
      <Button color="secondary" onClick={handleClose}>
        {t('customers.form.cancel')}
      </Button>
      <Button
        variant="contained"
        style={{ padding: '1rem 2.5rem' }}
        disabled={submitDisabled}
        data-testid="save-customer-edit"
        onClick={() => {
          handleSubmit();
          handleClose();
        }}
      >
        {t('customers.form.save')}
      </Button>
    </StyledHandlers>
  );

  return (
    <StyledNewDialog
      title={t('customers.form.edit_modal_title')}
      subTitle=""
      open={open}
      handleClose={handleClose}
      actionBar={actionBar}
      maxWidth="lg"
      content={<GeneralInformationForm />}
    />
  );
};

CustomerForm.propTypes = {
  open: T.bool.isRequired,
  handleClose: T.func.isRequired
};

export default CustomerForm;

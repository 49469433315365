import React from 'react';
import T from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Card, CardHeader, IconButton, Tooltip } from '@mui/material';

import { NotifyIcon, NotifyTypo } from 'web-components';

import { getCountryNameFromCountryCode, getInitials } from 'helpers/utils';
import { STATUS_INVALID, STATUS_VALID } from 'attrs/salesforce';

import moment from 'moment';
import { CustomerOverviewContent, OverviewColumn, OverviewRow, SapId } from './elements';

const labelAmend = 'customers.overview';

const formatDate = dateValue => {
  if (dateValue) {
    return moment(dateValue, 'YYYY-MM-DD[T]HH:mm:ssZ').format('DD/MM/YYYY');
  }
  return false;
};

const CustomerFields = (customer, lang) => {
  const { t } = useTranslation();

  return [
    {
      rows: [
        { title: 'customers.form.name', value: customer?.name || '-', id: 1 },
        {
          title: 'customers.form.country',
          value: getCountryNameFromCountryCode(lang, customer?.phone?.country) || '-',
          id: 2
        }
      ],
      id: 1
    },
    {
      rows: [
        { title: 'customers.form.email', value: customer?.support?.email || '-', id: 3 },
        { title: 'customers.form.phone', value: customer?.support.phone || '-', id: 4 }
      ],
      id: 2
    },
    {
      rows: [
        {
          title: 'customers.form.sap_account',
          value: (
            <SapId>
              {customer?.sap_account || '-'}
              {customer?.sap_status && customer?.sap_status === STATUS_INVALID && (
                <Tooltip title={t(`${labelAmend}.invalid_sap_id`)} placement="top" arrow>
                  <IconButton sx={{ p: 0, m: 0 }}>
                    <NotifyIcon fontSize="small" iconName="checkmarkFilled" viewBox="0 0 16 16" />
                  </IconButton>
                </Tooltip>
              )}
              {customer?.sap_status && customer?.sap_status === STATUS_VALID && (
                <NotifyIcon
                  style={{ fontSize: '14px', marginTop: '5px' }}
                  iconName="runningMachine"
                  viewBox="0 0 16 16"
                />
              )}
            </SapId>
          ),
          id: 5
        },
        { title: 'customers.form.account_creation_date', value: formatDate(customer?.created_at) || '-', id: 6 }
      ],
      id: 3
    }
  ];
};

const Fields = ({ customer, lang }) => {
  const { t } = useTranslation();
  const fields = CustomerFields(customer, lang);

  return fields.map(field => (
    <OverviewColumn key={`OverviewColumn_${field.id}`}>
      {field.rows.map(({ title, value, id }) => {
        const valueString = typeof value === 'string';
        return (
          <OverviewRow key={`OverviewRow_${id}`}>
            <NotifyTypo.Subtitle1>{t(title)}</NotifyTypo.Subtitle1>
            <Box>{valueString ? <NotifyTypo.Body2 fontSize="0.875rem">{value}</NotifyTypo.Body2> : value}</Box>
          </OverviewRow>
        );
      })}
    </OverviewColumn>
  ));
};

const CustomerDetailWrapper = ({ customer, handleEdit }) => {
  const { t } = useTranslation();
  const { data } = useSelector(state => state?.user?.user);

  const language = data?.language?.split('_')[0];

  return (
    <Card sx={{ p: 4 }}>
      <CardHeader
        sx={{ p: 0 }}
        action={
          <Button
            color="primary"
            variant="outlined"
            onClick={handleEdit}
            sx={{ p: 1.5, gap: 1, display: 'flex', height: '50px', width: '96px' }}
            data-testid="edit-customer"
          >
            <NotifyIcon iconName="edit" fontSize="medium" />
            <NotifyTypo.Body1 fontWeight="500">Edit</NotifyTypo.Body1>
          </Button>
        }
        title={t('customers.detail.customer')}
        titleTypographyProps={{
          fontWeight: '500',
          variant: 'Body1'
        }}
      />

      <CustomerOverviewContent>
        <Avatar
          style={{
            height: '80px',
            width: '80px',
            border: '1px solid #DDDDDD',
            marginRight: 20,
            background: 'linear-gradient(0deg, #ffffffb3, #ffffffb3), #58BAAA',
            color: '#58BAAA'
          }}
          src={customer?.logo}
        >
          {!customer?.logo && getInitials(customer?.name)}
        </Avatar>
        <Fields customer={customer} lang={language} />
      </CustomerOverviewContent>
    </Card>
  );
};

CustomerDetailWrapper.propTypes = {
  customer: T.shape({
    id: T.string,
    logo: T.string,
    'e-mail': T.string,
    name: T.string,
    sap_account: T.string,
    sap_status: T.string,
    created_at: T.string,
    phone: T.shape({ number: T.string, country: T.string }),
    country: T.string,
    support: T.shape({
      support_id: T.number,
      name: T.string,
      country_id: T.string,
      country_code: T.number,
      phone: T.string,
      email: T.string
    }),
    support_id: T.number,
    enabled_config: T.shape({
      batch: T.bool,
      data_exports: T.bool
    })
  }).isRequired,
  handleEdit: T.func.isRequired
};

export default CustomerDetailWrapper;
